import * as Yup from "yup";

export const checkoutSchema = Yup.object().shape({
  email: Yup.string().required(),
  fullName: Yup.string().required(),
  shippingAddress: Yup.object().shape({
    address: Yup.string().required(),
    city: Yup.string().required(),
    postalCode: Yup.string().required(),
    country: Yup.string().required(),
  }),
  paymentMethod: Yup.string().required(),
});
