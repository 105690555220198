import styled from "styled-components";

export const StyledMapContainer = styled.div`
  position: relative;
  text-align: right;
  width: 100%;
  height: 500px;

  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 500px;
  }
  .gmap_iframe {
    width: 100% !important;
    height: 500px !important;
  }
`;
{
  /* <style>.</style> */
}
