import styled from "styled-components";

export const PageContentContainer = styled.div`
  /* background-color: green; */
  position: relative !important;
`;

export const LoadDisplayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: green; */
`;
