import { configureStore } from "@reduxjs/toolkit";
import { productSlice } from "../features/product/productsSlice";
import cartReducer from "../features/cart/cartSlice";

export const store = configureStore({
  reducer: {
    products: productSlice.reducer,
    cart: cartReducer,
  },
});
