import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItem, removeItem } from "../../features/cart/cartSlice";
// import { addItemToCart } from '../store/cartSlice';
const Cart = () => {
  const dispatch = useDispatch();
  const [requiredQuantity, setRequiredQuantity] = useState(1);
  const [editedQuantity, setEditedQuantity] = useState({});
  const cartItems = useSelector((state) => state.cart.items);

  const handleAddToCart = (item) => {
    dispatch(addItem(item));
  };

  const handleQuantityChange = (item, newQuantity) => {
    dispatch(
      addItem({
        ...item,
        requiredQuantity: newQuantity,
      })
    );
  };

  const updateQuantity = (itemId) => {
    dispatch(
      addItem({
        id: itemId,
        requiredQuantity: editedQuantity[itemId],
      })
    );
  };

  const handleRemoveFromCart = (item) => {
    dispatch(removeItem(item));
    setEditedQuantity((prev) => {
      const newEditedQuantity = { ...prev };
      delete newEditedQuantity[item._id];
      return newEditedQuantity;
    });
  };

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.requiredQuantity,
    0
  );
  const shippingPrice = totalPrice >= 250 ? 0 : 50;
  console.log("Cart", cartItems);
  return (
    <main class="main">
      <div
        class="page-header text-center"
        style={{ backgroundImage: "url('assets/images/page-header-bg.jpg')" }}
      >
        <div class="container">
          <h1 class="page-title">
            Shopping Cart<span>Shop</span>
          </h1>
        </div>
        {/* <!-- End .container --> */}
      </div>
      {/* <!-- End .page-header --> */}
      <nav aria-label="breadcrumb" class="breadcrumb-nav">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="index-25.html">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#">Shop</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Shopping Cart
            </li>
          </ol>
        </div>
        {/* <!-- End .container --> */}
      </nav>
      {/* <!-- End .breadcrumb-nav --> */}

      <div class="page-content">
        <div class="cart">
          <div class="container">
            <div class="row">
              <div class="col-lg-9">
                <table class="table table-cart table-mobile">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {cartItems?.map((item) => (
                      <tr>
                        <td class="product-col">
                          <div class="product">
                            <figure class="product-media">
                              <a href="#">
                                <img
                                  src={item?.frontImage}
                                  alt="Product image"
                                />
                              </a>
                            </figure>

                            <h3 class="product-title">
                              <a href="#">{item?.name}</a>
                            </h3>
                            {/* <!-- End .product-title --> */}
                          </div>
                          {/* <!-- End .product --> */}
                        </td>
                        <td class="price-col">${item?.price}</td>
                        <td class="quantity-col">
                          <div class="cart-product-quantity">
                            <input
                              type="number"
                              class="form-control"
                              onChange={(e) =>
                                handleQuantityChange(item, e.target.value)
                              }
                              value={item?.requiredQuantity}
                              // value={item?.requiredQuantity}
                              min="1"
                              max="10"
                              step="1"
                              data-decimals="0"
                              required
                            />
                          </div>
                          {/* <!-- End .cart-product-quantity --> */}
                        </td>
                        <td class="total-col">
                          ${item.requiredQuantity * item.price}
                        </td>
                        <td class="remove-col">
                          <button
                            class="btn-remove"
                            onClick={() => handleRemoveFromCart(item)}
                          >
                            <i class="icon-close"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* <!-- End .table table-wishlist --> */}

                <div class="cart-bottom">
                  <div class="cart-discount">
                    <form action="#">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          required
                          placeholder="coupon code"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-primary-2"
                            type="submit"
                          >
                            <i class="icon-long-arrow-right"></i>
                          </button>
                        </div>
                        {/* <!-- .End .input-group-append --> */}
                      </div>
                      {/* <!-- End .input-group --> */}
                    </form>
                  </div>
                  {/* <!-- End .cart-discount --> */}

                  <a href="#" class="btn btn-outline-dark-2">
                    <span>UPDATE CART</span>
                    <i class="icon-refresh"></i>
                  </a>
                </div>
                {/* <!-- End .cart-bottom --> */}
              </div>
              {/* <!-- End .col-lg-9 --> */}
              <aside class="col-lg-3">
                <div class="summary summary-cart">
                  <h3 class="summary-title">Cart Total</h3>
                  {/* <!-- End .summary-title --> */}

                  <table class="table table-summary">
                    <tbody>
                      <tr class="summary-subtotal">
                        <td>Subtotal:</td>
                        <td>${totalPrice}</td>
                      </tr>
                      {/* <!-- End .summary-subtotal --> */}
                      <tr class="summary-shipping">
                        <td>Shipping:</td>
                        <td>&nbsp;</td>
                      </tr>

                      {shippingPrice === 0 ? (
                        <tr class="summary-shipping-row">
                          <td>
                            <div class="custom-control custom-radio">
                              <input
                                checked
                                type="radio"
                                id="standart-shipping"
                                name="shipping"
                                class="custom-control-input"
                              />
                              <label
                                class="custom-control-label"
                                for="standart-shipping"
                              >
                                Free shipping:
                              </label>
                            </div>
                            {/* <!-- End .custom-control --> */}
                          </td>
                          <td>$0.00</td>
                        </tr>
                      ) : (
                        <tr class="summary-shipping-row">
                          <td>
                            <div class="custom-control custom-radio">
                              <input
                                checked
                                type="radio"
                                id="standart-shipping"
                                name="shipping"
                                class="custom-control-input"
                              />
                              <label
                                class="custom-control-label"
                                for="standart-shipping"
                              >
                                Standard:
                              </label>
                            </div>
                            {/* <!-- End .custom-control --> */}
                          </td>
                          <td>$50.00</td>
                        </tr>
                      )}

                      {/* <!-- End .summary-shipping-row --> */}

                      {/* <tr class="summary-shipping-estimate">
                        <td>
                          Estimate for Your Country
                          <br /> <a href="dashboard.html">Change address</a>
                        </td>
                        <td>&nbsp;</td>
                      </tr> */}
                      {/* <!-- End .summary-shipping-estimate --> */}

                      <tr class="summary-total">
                        <td>Total:</td>
                        <td>${totalPrice + shippingPrice}</td>
                      </tr>
                      {/* <!-- End .summary-total --> */}
                    </tbody>
                  </table>
                  {/* <!-- End .table table-summary --> */}

                  <a
                    href="/checkout"
                    class="btn btn-outline-primary-2 btn-order btn-block"
                  >
                    PROCEED TO CHECKOUT
                  </a>
                </div>
                {/* <!-- End .summary --> */}

                <a href="/" class="btn btn-outline-dark-2 btn-block mb-3">
                  <span>CONTINUE SHOPPING</span>
                  <i class="icon-refresh"></i>
                </a>
              </aside>
              {/* <!-- End .col-lg-3 --> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
          {/* <!-- End .container --> */}
        </div>
        {/* <!-- End .cart --> */}
      </div>
      {/* <!-- End .page-content --> */}
    </main>
  );
};

export default Cart;
