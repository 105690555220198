import React from "react";
import { ModalContainer } from "./style";

const BankDetails = ({ closeModal }) => {
  return (
    <ModalContainer>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Bank Details</h5>
          </div>
          <div className="modal-body custom-modal-body">
            <div className="bankDetails">
              <p>
                Please fill in your billing details to get product information
                on your mail and send a screenshot of payment to
                qi@limitlessidentityfashion.com
              </p>
              <div className="details">
                <table>
                  <tr>
                    <td className="bold">Bank name</td>
                    <td>Bank of Montreal</td>
                  </tr>
                  <tr>
                    <td className="bold">Bank Address</td>
                    <td>5800 Mavis Rd, Mississauga, On, L5V3B7</td>
                  </tr>
                  <tr>
                    <td className="bold">Account Name </td>
                    <td>Limitless Identity Fashion inc</td>
                  </tr>
                  <tr>
                    <td className="bold">Account Address</td>
                    <td> 6625 falconer dr unit 64 Mississauga, ON L5N0C7.</td>
                  </tr>
                  <tr>
                    <td className="bold"> Acc #</td>
                    <td>4760-093</td>
                  </tr>
                  <tr>
                    <td className="bold">Transit #</td>
                    <td>36322</td>
                  </tr>
                  <tr>
                    <td className="bold">Institution #</td>
                    <td>001</td>
                  </tr>
                  <tr>
                    <td className="bold">Swift Code</td>
                    <td>BOFMCAM2</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              onClick={closeModal}
              type="button"
              className="btn btn-primary"
            >
              close
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default BankDetails;
