import React from "react";
import HomeSlider from "./slider";
import EndCarousel from "./slider/end-carousel";
import Customizeable from "./others/customizeable";
import FeaturedIndex from "./products/featured/featured-index";
import VideoBanner from "./others/video-banner";
import Special from "./products/special";
import NewArrival from "./new arrivals/new-arrival";

const HomeComp = () => {
  return (
    <>
      <link rel="stylesheet" href="/assets/css/demos/demo-5.css" />
      <HomeSlider />
      <EndCarousel />
      <Customizeable />
      <div class="mb-3"></div>
      <FeaturedIndex />
      <VideoBanner />
      <Special />
      <NewArrival />
    </>
  );
};

export default HomeComp;
