import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api_url } from "../../features/api_url";
import { checkoutSchema } from "../formValidation/check-form-validation";
import { toast } from "react-toastify";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PaypalCheckout from "./paypal";
import PaypalTest from "./paypal-test";
import PayPalCheckout from "./paypal-checkout";
import PayPalCardPayment from "./paypal-card-payment";
import BankDetails from "../modal/bank-details";

const Checkout = () => {
  const cartItems = useSelector((state) => state.cart.items);
  const subTotal = cartItems.reduce(
    (total, item) => total + item.price * item.requiredQuantity,
    0
  );
  const [shippingPrice, setShippingPrice] = useState(50);

  const [displayBankDetails, setDisplayBankDetails] = useState(false);

  useEffect(() => {
    subTotal >= 250 && setShippingPrice(0);
  });

  const totalPrice = subTotal + shippingPrice;

  const handleSelectShippingMethod = (shipMethod) => {
    shipMethod === "express" && subTotal <= 249
      ? setShippingPrice(50)
      : shipMethod === "courrier" && subTotal <= 249
      ? setShippingPrice(11)
      : setShippingPrice(50);
  };
  const [loading, setLoading] = useState(false);
  const transformedArray = cartItems.map((item) => ({
    name: item.name,
    qty: item.requiredQuantity,
    image: item.frontImage,
    price: item.price,
    product: item._id,
  }));
  // console.log("transformed array", transformedArray);
  const [checkoutData, setCheckoutData] = useState({
    email: "",
    fullName: "",
    orderItems: transformedArray,
    shippingAddress: {
      address: "",
      city: "",
      postalCode: "",
      country: "",
    },
    paymentMethod: "Bank transfer",
    paymentResult: {
      id: "009",
      status: "success",
      update_time: "20-10-2023",
      email_address: "zealousemmy12@gmail.com",
    },
    shippingPrice: shippingPrice,
    totalPrice: totalPrice,
    isPaid: true,
    paidTime: "Date",
    isDelivered: false,
    deliveryTime: "Date",
  });

  console.log(cartItems);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes("shippingAddress.")) {
      const field = name.split(".")[1]; // Extract the field name from the input's name
      setCheckoutData((prevData) => ({
        ...prevData,
        shippingAddress: {
          ...prevData.shippingAddress,
          [field]: value,
        },
      }));
    } else {
      setCheckoutData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    console.log(checkoutData, "checkout data updated");
  };
  const handlePaymentMethodClick = (selectedMethod) => {
    selectedMethod === "Bank transfer" && setDisplayBankDetails(true);
    setCheckoutData((prevData) => ({
      ...prevData,
      paymentMethod: selectedMethod,
    }));
  };

  const handleCheckOut = async (e) => {
    e.preventDefault();
    // console.log(checkoutData, "Check out data");
    setLoading(true);
    const validateCheckoutData = await checkoutSchema.isValid(checkoutData);
    if (validateCheckoutData === true) {
      try {
        const response = await axios.post(
          `${api_url}/create-order`,
          checkoutData
        );
        console.log(response, "success response");
        // console.log(checkoutData, "response");
        if (response.status === 200) {
          setLoading(false);
          toast("order details has been sent to your mailbox");
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.error("all inputs with * are required");
    }
  };

  const handleCheckOutTwo = async (details) => {
    console.log(details, "details details", details.payer.email_address);
    const data = {
      email: details.payer.email_address,
      fullName: details.purchase_units[0].shipping.name.full_name,
      orderItems: transformedArray,
      shippingAddress: {
        address: details.purchase_units[0].shipping.address.address_line_1,
        city: details.purchase_units[0].shipping.address.admin_area_2,
        postalCode: details.purchase_units[0].shipping.address.postal_code,
        country: details.purchase_units[0].shipping.address.country_code,
      },
      paymentMethod: "Paypay",
      paymentResult: {
        id: details.id,
        status: "success",
        update_time: details.update_time,
        email_address: details.payer.email_address,
      },
      shippingPrice: 50,
      totalPrice: totalPrice,
      isPaid: true,
      paidTime: "Date",
      isDelivered: false,
      deliveryTime: "Date",
    };
    try {
      console.log(data, "data data datat");
      const response = await axios.post(`${api_url}/create-order`, data);
      console.log(response, "success response");
      // console.log(checkoutData, "response");
      if (response.status === 200) {
        setLoading(false);
        toast("order details has been sent to your mailbox");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseBankDetails = () => {
    setDisplayBankDetails(false);
  };

  return (
    <main class="main">
      {/* <PaypalCheckout /> */}
      {/* <PaypalTest /> */}
      {/* <PayPalCardPayment /> */}
      {displayBankDetails && (
        <BankDetails closeModal={handleCloseBankDetails} />
      )}

      <div
        class="page-header text-center"
        style={{ backgroundImage: "url('assets/images/page-header-bg.jpg')" }}
      >
        <div class="container">
          <h1 class="page-title">
            Checkout<span>Shop</span>
          </h1>
        </div>
        {/* <!-- End .container --> */}
      </div>
      {/* <!-- End .page-header --> */}
      <nav aria-label="breadcrumb" class="breadcrumb-nav">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="index-25.html">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#">Shop</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Checkout
            </li>
          </ol>
        </div>
        {/* <!-- End .container --> */}
      </nav>
      {/* <!-- End .breadcrumb-nav --> */}

      <div class="page-content">
        <div class="checkout">
          <div class="container">
            <div class="checkout-discount">
              <form action="#">
                <input
                  type="text"
                  class="form-control"
                  required
                  id="checkout-discount-input"
                />
                <label for="checkout-discount-input" class="text-truncate">
                  Have a coupon? <span>Click here to enter your code</span>
                </label>
              </form>
            </div>
            {/* <!-- End .checkout-discount --> */}
            <form action="#">
              <div class="row">
                <div class="col-lg-9">
                  <h2 class="checkout-title">Billing Details</h2>
                  {/* <!-- End .checkout-title --> */}
                  <div class="row">
                    <div class="col-sm-6">
                      <label>Full Name *</label>
                      <input
                        name="fullName"
                        onChange={handleInputChange}
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    {/* <!-- End .col-sm-6 --> */}

                    <div class="col-sm-6">
                      <label>Email address *</label>
                      <input
                        name="email"
                        onChange={handleInputChange}
                        type="email"
                        class="form-control"
                        required
                      />
                    </div>
                    {/* <!-- End .col-sm-6 --> */}
                  </div>
                  {/* <!-- End .row --> */}

                  <label>Country *</label>
                  <input
                    name="shippingAddress.country"
                    onChange={handleInputChange}
                    type="text"
                    class="form-control"
                    required
                  />

                  <label>Street address *</label>
                  <input
                    type="text"
                    class="form-control"
                    name="shippingAddress.address"
                    onChange={handleInputChange}
                    placeholder="House number and Street name"
                    required
                  />

                  <div class="row">
                    <div class="col-sm-6">
                      <label>Town / City *</label>
                      <input
                        type="text"
                        name="shippingAddress.city"
                        onChange={handleInputChange}
                        class="form-control"
                        required
                      />
                    </div>
                    {/* <!-- End .col-sm-6 --> */}

                    <div class="col-sm-6">
                      <label>State *</label>
                      <input
                        name="shippingAddress.state"
                        onChange={handleInputChange}
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    {/* <!-- End .col-sm-6 --> */}
                  </div>
                  {/* <!-- End .row --> */}

                  <div class="row">
                    <div class="col-sm-6">
                      <label>Postcode / ZIP *</label>
                      <input
                        name="shippingAddress.postalCode"
                        onChange={handleInputChange}
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                    {/* <!-- End .col-sm-6 --> */}

                    {/* <div class="col-sm-6">
                      <label>Phone *</label>
                      <input type="tel" class="form-control" required />
                    </div> */}
                    {/* <!-- End .col-sm-6 --> */}
                  </div>
                  {/* <!-- End .row --> */}

                  {/* <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="checkout-create-acc"
                    />
                    <label
                      class="custom-control-label"
                      for="checkout-create-acc"
                    >
                      Create an account?
                    </label>
                  </div> */}
                  {/* <!-- End .custom-checkbox --> */}

                  {/* <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="checkout-diff-address"
                    />
                    <label
                      class="custom-control-label"
                      for="checkout-diff-address"
                    >
                      Ship to a different address?
                    </label>
                  </div> */}
                  {/* <!-- End .custom-checkbox --> */}

                  {/* <label>Order notes (optional)</label>
                  <textarea
                    class="form-control"
                    cols="30"
                    rows="4"
                    placeholder="Notes about your order, e.g. special notes for delivery"
                  ></textarea> */}
                </div>
                {/* <!-- End .col-lg-9 --> */}
                <aside class="col-lg-3">
                  <div class="summary">
                    <h3 class="summary-title">Your Order</h3>
                    {/* <!-- End .summary-title --> */}

                    <table class="table table-summary">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Total</th>
                        </tr>
                      </thead>

                      <tbody>
                        {cartItems?.map((item) => (
                          <tr>
                            <td>
                              <a href="#">{item?.name}</a>
                            </td>
                            <td>${item?.price}.00</td>
                          </tr>
                        ))}

                        <tr class="summary-subtotal">
                          <td>Subtotal:</td>
                          <td>${subTotal}.00</td>
                        </tr>
                        {/* <!-- End .summary-subtotal --> */}
                        {shippingPrice !== 0 && (
                          <div class="accordion-summary" id="accordion-payment">
                            <div class="card">
                              <div class="card-header" id="heading-9">
                                <h2 class="card-title">
                                  <a
                                    role="button"
                                    data-toggle="collapse"
                                    href="#collapse-9"
                                    aria-expanded="true"
                                    aria-controls="collapse-9"
                                    onClick={() =>
                                      handleSelectShippingMethod("express")
                                    }
                                  >
                                    Express shipping ($50)
                                  </a>
                                </h2>
                              </div>
                              {/* <!-- End .card-header --> */}
                              <div
                                id="collapse-9"
                                class="collapse show"
                                aria-labelledby="heading-9"
                                data-parent="#accordion-payment"
                              >
                                <div class="card-body">
                                  {/* Items gets delivered in ten day */}
                                </div>
                                {/* <!-- End .card-body --> */}
                              </div>
                              {/* <!-- End .collapse --> */}
                            </div>
                            <div class="card">
                              <div class="card-header" id="heading-8">
                                <h2 class="card-title">
                                  <a
                                    role="button"
                                    data-toggle="collapse"
                                    href="#collapse-8"
                                    aria-expanded="true"
                                    aria-controls="collapse-8"
                                    onClick={() =>
                                      handleSelectShippingMethod("courrier")
                                    }
                                  >
                                    courrier shipping ($11)
                                  </a>
                                </h2>
                              </div>
                              {/* <!-- End .card-header --> */}
                              <div
                                id="collapse-8"
                                class="collapse show"
                                aria-labelledby="heading-8"
                                data-parent="#accordion-payment"
                              >
                                <div class="card-body">
                                  {/* Items gets delivered in ten day */}
                                </div>
                                {/* <!-- End .card-body --> */}
                              </div>
                              {/* <!-- End .collapse --> */}
                            </div>
                          </div>
                        )}

                        <tr>
                          <td>Shipping:</td>
                          <td>${shippingPrice}.00</td>
                        </tr>
                        <tr class="summary-total">
                          <td>Total:</td>
                          <td>${totalPrice}.00</td>
                        </tr>
                        {/* <!-- End .summary-total --> */}
                      </tbody>
                    </table>
                    {/* <!-- End .table table-summary --> */}

                    <div class="accordion-summary" id="accordion-payment">
                      <div class="card">
                        <div class="card-header" id="heading-1">
                          <h2 class="card-title">
                            <a
                              role="button"
                              data-toggle="collapse"
                              href="#collapse-1"
                              aria-expanded="true"
                              aria-controls="collapse-1"
                              onClick={() =>
                                handlePaymentMethodClick("Bank transfer")
                              }
                            >
                              Direct bank transfer
                            </a>
                          </h2>
                        </div>
                        {/* <!-- End .card-header --> */}
                        <div
                          id="collapse-1"
                          class="collapse show"
                          aria-labelledby="heading-1"
                          data-parent="#accordion-payment"
                        >
                          <div class="card-body">
                            Make your payment directly into our bank account
                            Please send in screenshot or payment proof to our
                            mail(qi@limitlessidentityfashion.com) or whatsapp
                            (+1 (365) 773-7390). Your order will not be shipped
                            until the funds have cleared in our account.
                          </div>
                          {/* <!-- End .card-body --> */}
                        </div>
                        {/* <!-- End .collapse --> */}
                      </div>
                      {/* <!-- End .card --> */}

                      {/* <!-- End .card --> */}
                      <div class="card">
                        <div class="card-header" id="heading-4">
                          <h2 class="card-title">
                            <a
                              class="collapsed"
                              role="button"
                              data-toggle="collapse"
                              href="#collapse-4"
                              aria-expanded="false"
                              aria-controls="collapse-4"
                              onClick={() => handlePaymentMethodClick("PayPal")}
                            >
                              PayPal
                            </a>
                          </h2>
                        </div>
                        {/* <!-- End .card-header --> */}
                        <div
                          id="collapse-4"
                          class="collapse"
                          aria-labelledby="heading-4"
                          data-parent="#accordion-payment"
                        >
                          <div class="card-body">
                            A secure, convenient payment option. Shop with ease
                            - use your PayPal account for quick, hassle-free
                            transactions on our checkout page.
                            <small class="float-right">
                              <a
                                target="_blank"
                                href="https://www.paypal.com/us/digital-wallet/how-paypal-works"
                              >
                                What is PayPal?
                              </a>
                            </small>
                          </div>
                          {/* <!-- End .card-body --> */}
                          <PayPalCheckout
                            completeCheckout={handleCheckOutTwo}
                            // transformedArray={transformedArray}
                            amount={totalPrice}
                          />
                        </div>
                        {/* <!-- End .collapse --> */}
                      </div>
                      {/* <!-- End .card --> */}

                      <div class="card">
                        <div class="card-header" id="heading-5">
                          <h2 class="card-title">
                            <a
                              class="collapsed"
                              role="button"
                              data-toggle="collapse"
                              href="#collapse-5"
                              aria-expanded="false"
                              aria-controls="collapse-5"
                              onClick={() =>
                                handlePaymentMethodClick("Credit card")
                              }
                            >
                              Credit Card
                              <img
                                src="assets/images/payments-summary.png"
                                alt="payments cards"
                              />
                            </a>
                          </h2>
                        </div>
                        {/* <!-- End .card-header --> */}
                        <div
                          id="collapse-5"
                          class="collapse"
                          aria-labelledby="heading-5"
                          data-parent="#accordion-payment"
                        >
                          <div class="card-body">
                            {" "}
                            Donec nec justo eget felis facilisis fermentum.Lorem
                            ipsum dolor sit amet, consectetuer adipiscing elit.
                            Donec odio. Quisque volutpat mattis eros. Lorem
                            ipsum dolor sit ame.
                          </div>
                          {/* <!-- End .card-body --> */}
                          <PayPalCheckout
                            completeCheckout={handleCheckOutTwo}
                            // transformedArray={transformedArray}
                            amount={totalPrice}
                          />
                        </div>
                        {/* <!-- End .collapse --> */}
                      </div>
                      {/* <!-- End .card --> */}
                    </div>
                    {/* <!-- End .accordion --> */}

                    <button
                      onClick={handleCheckOut}
                      class="btn btn-outline-primary-2 btn-order btn-block"
                    >
                      <span class="btn-text">
                        {loading === false
                          ? "Place Order"
                          : "placing order....."}
                      </span>
                      <span class="btn-hover-text">
                        {loading === false
                          ? "Proceed to Checkout"
                          : "placing order....."}
                      </span>
                    </button>
                  </div>
                  {/* <!-- End .summary --> */}
                </aside>
                {/* <!-- End .col-lg-3 --> */}
              </div>
              {/* <!-- End .row --> */}
            </form>
          </div>
          {/* <!-- End .container --> */}
        </div>
        {/* <!-- End .checkout --> */}
      </div>
      {/* <!-- End .page-content --> */}
    </main>
  );
};

export default Checkout;
