import React from "react";
import { EndCarouselContainer } from "./style";

const EndCarousel = () => {
  return (
    <EndCarouselContainer>
      <div
        class="brands-border owl-carousel owl-simple mb-5"
        data-toggle="owl"
        data-owl-options='{
        "nav": true, 
        "dots": true,
        "margin": 0,
        "loop": true,
        "autoplay": true,
        "autoplayTimeout": 4000,
        "responsive": {
            "0": {
                "items":2
            },
            "420": {
                "items":3
            },
            "600": {
                "items":4
            },
            "900": {
                "items":5
            },
            "1024": {
                "items":6
            },
            "1360": {
                "items":7
            }
        }
    }'
      >
        <a href="#" class="brand">
          <img src="assets/images/brands/1.png" alt="Brand Name" />
        </a>

        <a href="#" class="brand">
          <img src="assets/images/brands/2.png" alt="Brand Name" />
        </a>

        <a href="#" class="brand">
          <img src="assets/images/brands/3.png" alt="Brand Name" />
        </a>

        <a href="#" class="brand">
          <img src="assets/images/brands/4.png" alt="Brand Name" />
        </a>

        <a href="#" class="brand">
          <img src="assets/images/brands/5.png" alt="Brand Name" />
        </a>

        <a href="#" class="brand">
          <img src="assets/images/brands/6.png" alt="Brand Name" />
        </a>

        <a href="#" class="brand">
          <img src="assets/images/brands/7.png" alt="Brand Name" />
        </a>
      </div>
    </EndCarouselContainer>
  );
};

export default EndCarousel;
