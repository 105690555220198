import React from "react";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const isNursingSrub = location.pathname === "/nursing-scrubs";
  return (
    <footer class="footer footer-2">
      <div class="icon-boxes-container">
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-lg-3">
              <div class="icon-box icon-box-side">
                <span class="icon-box-icon text-dark">
                  <i class="icon-rocket"></i>
                </span>
                <div class="icon-box-content">
                  <h3 class="icon-box-title">Free Shipping</h3>
                  {/* <!-- End .icon-box-title --> */}
                  <p>orders $500 or more</p>
                </div>
                {/* <!-- End .icon-box-content --> */}
              </div>
              {/* <!-- End .icon-box --> */}
            </div>
            {/* <!-- End .col-sm-6 col-lg-3 --> */}

            <div class="col-sm-6 col-lg-3">
              <div class="icon-box icon-box-side">
                <span class="icon-box-icon text-dark">
                  <i class="icon-rotate-left"></i>
                </span>

                <div class="icon-box-content">
                  <h3 class="icon-box-title">Free Returns</h3>
                  {/* <!-- End .icon-box-title --> */}
                  <p>within 7 days</p>
                </div>
                {/* <!-- End .icon-box-content --> */}
              </div>
              {/* <!-- End .icon-box --> */}
            </div>
            {/* <!-- End .col-sm-6 col-lg-3 --> */}

            <div class="col-sm-6 col-lg-3">
              <div class="icon-box icon-box-side">
                <span class="icon-box-icon text-dark">
                  <i class="icon-info-circle"></i>
                </span>

                <div class="icon-box-content">
                  <h3 class="icon-box-title">Get 30% Off Item</h3>
                  {/* <!-- End .icon-box-title --> */}
                  <p>When you buy more 2</p>
                </div>
                {/* <!-- End .icon-box-content --> */}
              </div>
              {/* <!-- End .icon-box --> */}
            </div>
            {/* <!-- End .col-sm-6 col-lg-3 --> */}

            <div class="col-sm-6 col-lg-3">
              <div class="icon-box icon-box-side">
                <span class="icon-box-icon text-dark">
                  <i class="icon-life-ring"></i>
                </span>

                <div class="icon-box-content">
                  <h3 class="icon-box-title">We Support</h3>
                  {/* <!-- End .icon-box-title --> */}
                  <p>24/7 amazing services</p>
                </div>
                {/* <!-- End .icon-box-content --> */}
              </div>
              {/* <!-- End .icon-box --> */}
            </div>
            {/* <!-- End .col-sm-6 col-lg-3 --> */}
          </div>
          {/* <!-- End .row --> */}
        </div>
        {/* <!-- End .container --> */}
      </div>
      {/* <!-- End .icon-boxes-container --> */}

      <div
        class="footer-newsletter bg-image"
        style={{ backgroundImage: "url(assets/images/backgrounds/bg-2.jpg)" }}
      >
        <div class="container">
          <div class="heading text-center">
            <h3 class="title">Get The Latest Deals</h3>
            {/* <!-- End .title --> */}
            <p class="title-desc">
              and be <span>the first to get</span> notified of our latest wears
            </p>
            {/* <!-- End .title-desc --> */}
          </div>
          {/* <!-- End .heading --> */}

          <div class="row">
            <div class="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <form action="#">
                <div class="input-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter your Email Address"
                    aria-label="Email Adress"
                    aria-describedby="newsletter-btn"
                    required
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-primary"
                      type="submit"
                      id="newsletter-btn"
                    >
                      <span>Subscribe</span>
                      <i class="icon-long-arrow-right"></i>
                    </button>
                  </div>
                  {/* <!-- .End .input-group-append --> */}
                </div>
                {/* <!-- .End .input-group --> */}
              </form>
            </div>
            {/* <!-- End .col-sm-10 offset-sm-1 col-lg-6 offset-lg-3 --> */}
          </div>
          {/* <!-- End .row --> */}
        </div>
        {/* <!-- End .container --> */}
      </div>
      {/* <!-- End .footer-newsletter bg-image --> */}

      <div class="footer-middle">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-lg-6">
              <div class="widget widget-about">
                <img
                  src="/assets/images/demos/demo-2/logo.png"
                  class="footer-logo"
                  alt="Footer Logo"
                  width="80"
                  height="25"
                />
                <p>
                  LimitlessIdentityFashion is an approved supplier of the world
                  Para Powerlifting body. We provide access for member countries
                  and their athletes to have compliant jerseys and apparels that
                  are designed to reflect a country’s cultural identity.{" "}
                </p>

                <div class="widget-about-info">
                  <div class="row">
                    <div class="col-sm-6 col-md-4">
                      <span class="widget-about-title">
                        Got Question? Call us 24/7
                      </span>
                      <a href="tel:123456789">+1 (365) 773-7390</a>
                      {isNursingSrub && (
                        <h6 style={{ color: "white" }}>queen@lmnurse.ca</h6>
                      )}
                    </div>
                    {/* <!-- End .col-sm-6 --> */}
                    <div class="col-sm-6 col-md-8">
                      <span class="widget-about-title">Payment Method</span>
                      <figure class="footer-payments">
                        <img
                          src="/assets/images/payments.png"
                          alt="Payment methods"
                          width="272"
                          height="20"
                        />
                      </figure>
                      {/* <!-- End .footer-payments --> */}
                    </div>
                    {/* <!-- End .col-sm-6 --> */}
                  </div>
                  {/* <!-- End .row --> */}
                </div>
                {/* <!-- End .widget-about-info --> */}
              </div>
              {/* <!-- End .widget about-widget --> */}
            </div>
            {/* <!-- End .col-sm-12 col-lg-3 --> */}

            <div class="col-sm-4 col-lg-2">
              <div class="widget">
                <h4 class="widget-title">Information</h4>
                {/* <!-- End .widget-title --> */}

                <ul class="widget-list">
                  <li>
                    <a href="/about">About LIF</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact us</a>
                  </li>
                  <li>
                    <a href="/">Home page</a>
                  </li>
                </ul>
                {/* <!-- End .widget-list --> */}
              </div>
              {/* <!-- End .widget --> */}
            </div>
            {/* <!-- End .col-sm-4 col-lg-3 --> */}

            <div class="col-sm-4 col-lg-2">
              <div class="widget">
                <h4 class="widget-title">Customer Service</h4>
                {/* <!-- End .widget-title --> */}

                <ul class="widget-list">
                  <li>
                    <a href="/contact-us">Write us</a>
                  </li>
                  <li>
                    <a href="/lifting-suits">WPPO collections</a>
                  </li>
                  <li>
                    <a href="/profit-polos">Our Polos</a>
                  </li>
                </ul>
                {/* <!-- End .widget-list --> */}
              </div>
              {/* <!-- End .widget --> */}
            </div>
            {/* <!-- End .col-sm-4 col-lg-3 --> */}

            <div class="col-sm-4 col-lg-2">
              <div class="widget">
                <h4 class="widget-title">Partners</h4>
                {/* <!-- End .widget-title --> */}

                <ul class="widget-list">
                  <li>
                    <a href="/contact-us">Get in touch</a>
                  </li>
                  <li>
                    <a href="/cart">View Cart</a>
                  </li>
                  <li>
                    <a href="/contact-us">Help</a>
                  </li>
                </ul>
                {/* <!-- End .widget-list --> */}
              </div>
              {/* <!-- End .widget --> */}
            </div>
            {/* <!-- End .col-sm-64 col-lg-3 --> */}
          </div>
          {/* <!-- End .row --> */}
        </div>
        {/* <!-- End .container --> */}
      </div>
      {/* <!-- End .footer-middle --> */}

      <div class="footer-bottom">
        <div class="container">
          <p class="footer-copyright">
            Copyright © 2023 Limitless Identity Fashion. All Rights Reserved.
          </p>
          {/* <!-- End .footer-copyright --> */}
          <ul class="footer-menu">
            <li>
              <a href="#">Terms Of Use</a>
            </li>
            <li>
              <a href="#">Privacy Policy</a>
            </li>
          </ul>
          {/* <!-- End .footer-menu --> */}

          <div class="social-icons social-icons-color">
            <span class="social-label">Social Media</span>
            <a
              href="#"
              class="social-icon social-facebook"
              title="Facebook"
              target="_blank"
            >
              <i class="icon-facebook-f"></i>
            </a>
            <a
              href="#"
              class="social-icon social-twitter"
              title="Twitter"
              target="_blank"
            >
              <i class="icon-twitter"></i>
            </a>
            <a
              href="#"
              class="social-icon social-instagram"
              title="Instagram"
              target="_blank"
            >
              <i class="icon-instagram"></i>
            </a>
            <a
              href="#"
              class="social-icon social-youtube"
              title="Youtube"
              target="_blank"
            >
              <i class="icon-youtube"></i>
            </a>
            <a
              href="#"
              class="social-icon social-pinterest"
              title="Pinterest"
              target="_blank"
            >
              <i class="icon-pinterest"></i>
            </a>
          </div>
          {/* <!-- End .soial-icons --> */}
        </div>
        {/* <!-- End .container --> */}
      </div>
      {/* <!-- End .footer-bottom --> */}
    </footer>
  );
};

export default Footer;
