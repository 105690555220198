import React, { useState } from "react";
import { MobileNavContainer } from "./style";
import { NavLink } from "react-router-dom";

const MobileNav = ({ handleCloseBar }) => {
  const [childListDisplay, setChildListDisplay] = useState("");

  const handleWppoDisplay = () => {
    childListDisplay === ""
      ? setChildListDisplay("wppo")
      : setChildListDisplay("");
  };

  const handleBrandDisplay = () => {
    childListDisplay === ""
      ? setChildListDisplay("brand")
      : setChildListDisplay("");
  };

  return (
    <MobileNavContainer class="mobile-menu-container mobile-menu-light">
      <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close" onClick={handleCloseBar}>
          <i class="icon-close"></i>
        </span>

        <form action="#" method="get" class="mobile-search">
          <label for="mobile-search" class="sr-only">
            Search
          </label>
          <input
            type="search"
            class="form-control"
            name="mobile-search"
            id="mobile-search"
            placeholder="Search in..."
            required
          />
          <button class="btn btn-primary" type="submit">
            <i class="icon-search"></i>
          </button>
        </form>

        <nav class="mobile-nav">
          <ul class="mobile-menu">
            <li class="megamenu-container active">
              <NavLink reloadDocument to="/" class="sf-with">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink reloadDocument to="/about" class="sf-with">
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink onClick={handleWppoDisplay} to="#" class="#">
                WPPO Collections
              </NavLink>
              {childListDisplay === "wppo" && (
                <div className="list-child">
                  <li>
                    <NavLink reloadDocument to="/lifting-suits">
                      Lifting Suits
                    </NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/track-suits">
                      Track Suits
                    </NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/profit-polos">
                      Profit and Polos
                    </NavLink>
                  </li>
                </div>
              )}
            </li>
            <li>
              <NavLink to="#" onClick={handleBrandDisplay} class="sf-with-ul">
                Our Brands
              </NavLink>
              {childListDisplay === "brand" && (
                <div className="list-child">
                  <li>
                    <NavLink reloadDocument to="/280-degrees">
                      280 Degrees
                    </NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/varsity-jacket">
                      Varsity Jackets
                    </NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/top-leggings">
                      Crow top & Leggings
                    </NavLink>
                  </li>
                  <li>
                    <NavLink reloadDocument to="/profit-polos-two">
                      Profit and Polos
                    </NavLink>
                  </li>
                </div>
              )}
            </li>
            <li>
              <NavLink reloadDocument to="/nursing-scrubs" class="sf-with">
                Nursing Scrubs
              </NavLink>
            </li>
            <li>
              <NavLink reloadDocument to="/contact-us" class="sf-with">
                Contact Us
              </NavLink>
            </li>
          </ul>
          {/* <!-- End .menu --> */}
        </nav>
        {/* <!-- End .mobile-nav --> */}

        <div class="social-icons">
          <a href="#" class="social-icon" target="_blank" title="Facebook">
            <i class="icon-facebook-f"></i>
          </a>
          <a href="#" class="social-icon" target="_blank" title="Twitter">
            <i class="icon-twitter"></i>
          </a>
          <a href="#" class="social-icon" target="_blank" title="Instagram">
            <i class="icon-instagram"></i>
          </a>
          <a href="#" class="social-icon" target="_blank" title="Youtube">
            <i class="icon-youtube"></i>
          </a>
        </div>
        {/* <!-- End .social-icons --> */}
      </div>
      {/* <!-- End .mobile-menu-wrapper --> */}
    </MobileNavContainer>
  );
};

export default MobileNav;
