import React, { useEffect, useState } from "react";
import { fetchProductsByCategory } from "../../features/product/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../../features/cart/cartSlice";
import { toast } from "react-toastify";
import Modal from "../modal";
import { LoadDisplayContainer, PageContentContainer } from "./style";
import QRCode from "qrcode";
import { Audio, BallTriangle } from "react-loader-spinner";
const PageContent = () => {
  const [requiredQuantity, setRequiredQuantity] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const [categories, setCategories] = useState([]);

  const [paginationTotal, setPaginationTotal] = useState(0);

  const [paginationIndex, setPaginationIndex] = useState(1);

  const [totalCategories, setTotalCategories] = useState([]);

  // const [url, setUrl] = useState("");
  const [modalDisplay, setModalDisplay] = useState(false);
  const [qrcode, setQrcode] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductsByCategory("track suits"));
    // setProductsState(products?.items);
  }, []);

  const products = useSelector((state) => {
    return state.products;
  });

  useEffect(() => {
    console.log(
      paginationIndex,
      paginationTotal,
      categories,
      totalCategories,
      "products"
    );

    if (products?.items?.length > 0) {
      const totalArrays = [...products?.items];

      const parentArray = [];

      const total_arrays_value = products?.items?.length / 3;

      for (let i = 0; i < Math.ceil(total_arrays_value); i++) {
        parentArray?.push(totalArrays?.splice(0, 8));
      }

      setPaginationTotal(Math.ceil(total_arrays_value));

      setCategories(parentArray[0]);

      setTotalCategories(parentArray);

      setPaginationIndex(1);
    }
  }, [products]);

  const handlePages = () => {
    if (categories?.length > 0 && paginationIndex > 0) {
      if (paginationIndex + 1 < paginationTotal) {
        const indexState = paginationIndex + 1;

        setPaginationIndex(indexState);

        const pageControll = [
          ...categories,
          ...totalCategories[indexState - 1],
        ];

        setCategories(pageControll);
      }
    }
  };

  const GenerateQrCode = (id) => {
    const url = `https://lif-test-server.vercel.app/product-detail/${id}`;
    QRCode.toDataURL(url, (err, url) => {
      if (err) return console.error(err);
      console.log(url);
      setQrcode(url);
      setModalDisplay(true);
    });
  };

  const handleCloseModal = () => {
    setModalDisplay(false);
  };
  const handleAddToCart = (product) => {
    console.log(product, "add product to cart");
    // Dispatch the addItem action when the "Add to Cart" button is clicked
    dispatch(addItem({ ...product, requiredQuantity }));
    toast("item added to cart");
  };

  console.log(categories.length, "category length");
  return (
    <PageContentContainer className="page-content">
      {modalDisplay === true && (
        <Modal qrCode={qrcode} closeModal={handleCloseModal} />
      )}

      <div className="container">
        <div className="toolbox">
          <div className="toolbox-left">
            <a href="#" className="sidebar-toggler">
              <i className="icon-bars"></i>Filters
            </a>
          </div>
          {/* <!-- End .toolbox-left --> */}

          <div className="toolbox-center">
            <div className="toolbox-info">
              Showing{" "}
              <span>
                {categories?.length} of {products?.items?.length}
              </span>{" "}
              Products
            </div>
            {/* <!-- End .toolbox-info --> */}
          </div>
          {/* <!-- End .toolbox-center --> */}

          <div className="toolbox-right">
            <div className="toolbox-sort">
              <label htmlFor="sortby">Sort by:</label>
              <div className="select-custom">
                <select name="sortby" id="sortby" className="form-control">
                  <option value="popularity" selected="selected">
                    Most Popular
                  </option>
                  <option value="rating">Most Rated</option>
                  <option value="date">Date</option>
                </select>
              </div>
            </div>
            {/* <!-- End .toolbox-sort --> */}
          </div>
          {/* <!-- End .toolbox-right --> */}
        </div>
        {/* <!-- End .toolbox --> */}

        <div className="products">
          {products.isFetching === true && (
            <LoadDisplayContainer>
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#4fa94d"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </LoadDisplayContainer>
          )}
          <div className="row">
            {categories?.map((product) => (
              <div
                key={product._id}
                className="col-6 col-md-4 col-lg-4 col-xl-3"
              >
                <div className="product">
                  <figure className="product-media">
                    <span className="product-label label-new">New</span>
                    <a href={`/product-detail/${product._id}`}>
                      <img
                        src={product.frontImage}
                        alt="Product image"
                        className="product-image"
                      />
                    </a>

                    {/* <div className="product-action-vertical">
                      <a
                        href="#"
                        className="btn-product-icon btn-wishlist btn-expandable"
                      >
                        <span>add to wishlist</span>
                      </a>
                    </div> */}
                    {/* <!-- End .product-action --> */}

                    <div className="product-action action-icon-top">
                      <a
                        onClick={() => handleAddToCart(product)}
                        className="btn-product btn-cart"
                      >
                        <span>add to cart</span>
                      </a>
                      <a
                        href="#"
                        onClick={() => GenerateQrCode(product._id)}
                        className="btn-product btn-quickview"
                        title="Quick view"
                      >
                        <span>Get Qrcode</span>
                      </a>
                      {/* <a
                        href="#"
                        className="btn-product btn-compare"
                        title="Compare"
                      >
                        <span>compare</span>
                      </a> */}
                    </div>
                    {/* <!-- End .product-action --> */}
                  </figure>
                  {/* <!-- End .product-media --> */}

                  <div className="product-body">
                    <div className="product-cat">
                      <a href="#">Women</a>
                    </div>
                    {/* <!-- End .product-cat --> */}
                    <h3 className="product-title">
                      <a href="product.html">{product?.name}</a>
                    </h3>
                    {/* <!-- End .product-title --> */}
                    <div className="product-price">${product.price}</div>
                    {/* <!-- End .product-price --> */}
                    <div className="ratings-container">
                      <div className="ratings">
                        <div class="ratings-val" style={{ width: "0%" }}></div>
                        {/* <!-- End .ratings-val --> */}
                      </div>
                      {/* <!-- End .ratings --> */}
                      <span className="ratings-text">( 0 Reviews )</span>
                    </div>
                    {/* <!-- End .rating-container --> */}

                    <div className="product-nav product-nav-dots">
                      <a href="#" style={{ background: "#cc9966" }}>
                        <span className="sr-only">Color name</span>
                      </a>

                      <a
                        href="#"
                        className="active"
                        style={{ background: "#ebebeb" }}
                      >
                        <span className="sr-only">Color name</span>
                      </a>
                    </div>
                    {/* <!-- End .product-nav --> */}
                  </div>
                  {/* <!-- End .product-body --> */}
                </div>

                {/* <!-- End .product --> */}
              </div>
            ))}
            {/* <!-- End .col-sm-6 col-lg-4 col-xl-3 --> */}
          </div>
          {/* <!-- End .row --> */}

          <div className="load-more-container text-center">
            <a
              onClick={handlePages}
              className="btn btn-outline-darker btn-load-more"
            >
              More Products <i className="icon-refresh"></i>
            </a>
          </div>
          {/* <!-- End .load-more-container --> */}
        </div>
        {/* <!-- End .products --> */}
      </div>
      {/* <!-- End .container --> */}
    </PageContentContainer>
  );
};

export default PageContent;
