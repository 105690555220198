import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { api_url } from "../../features/api_url";
import axios from "axios";
import { toast } from "react-toastify";
import env from "react-dotenv";
function PayPalCheckout({ amount, completeCheckout, transformedArray }) {
  // const clientID =
  //   "AXjDX2ZG2uQnSLgUsihaeF1xL0iDOwXwMVHWyEprf-CZHj9X0JN9MUYtGc8n8LMopTmf_sloXA7JJiFY";

  const clientID = process.env.REACT_APP_CLIENT_ID;
  console.log(process.env.REACT_APP_CLIENT_ID, "client id paypal");
  const handleCheckOut = async ({
    email,
    fullName,
    address,
    country,
    city,
    paymentTime,
    postalCode,
  }) => {
    // e.preventDefault();
    console.log("got here no plays");
    try {
      const data = {
        email: email,
        fullName: fullName,
        orderItems: transformedArray,
        shippingAddress: {
          address: address,
          city: city,
          postalCode: postalCode,
          country: country,
        },
        paymentMethod: "paypal",
        paymentResult: {
          id: "009",
          status: "success",
          update_time: paymentTime,
          email_address: email,
        },
        shippingPrice: 50,
        totalPrice: amount,
        isPaid: true,
        paidTime: "Date",
        isDelivered: false,
        deliveryTime: "Date",
      };
      console.log(data, "get data paypal");
      const response = await axios.post(`${api_url}/create-order`, data);
      console.log(response, "success response");
      // console.log(checkout, "response");
      if (response.status === 200) {
        // setLoading(false);
        toast("order details has been sent to your mailbox");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PayPalScriptProvider options={{ "client-id": clientID }}>
      <PayPalButtons
        createOrder={(data, actions) => {
          // Create the order and return the order ID
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount,
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          // Capture the funds from the approved order
          console.log(data, "from data");
          return actions.order.capture().then(function (details) {
            // Handle the successful payment
            console.log(details, "detaails data");
            // handleCheckOut({
            //   fullName: details.purchase_units[0].shipping.name.full_name,
            //   email: details.payer.email,
            //   address:
            //     details.purchase_units[0].shipping.address.address_line_1,
            //   country: details.purchase_units[0].shipping.address.country_code,
            //   postalCode: details.shipping.address.postal_code,
            //   city: details.purchase_units[0].shipping.admin_area_2,
            //   paymentTime: details.update_time,
            // });
            completeCheckout(details);
          });
        }}
      />
    </PayPalScriptProvider>
  );
}

export default PayPalCheckout;
