import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import env from "react-dotenv";
function PayPalCardPayment() {
  const clientID =
    "AXjDX2ZG2uQnSLgUsihaeF1xL0iDOwXwMVHWyEprf-CZHj9X0JN9MUYtGc8n8LMopTmf_sloXA7JJiFY";
  // const clientID = process.env.CLIENT_ID;
  return (
    <PayPalScriptProvider
      options={{
        "client-id": clientID,
        fundingSource: "card",
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: "10.00",
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(function (details) {
            // Handle the successful payment
          });
        }}
      />
    </PayPalScriptProvider>
  );
}

export default PayPalCardPayment;
