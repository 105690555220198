import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducts,
  fetchProductsByCategory,
} from "../../../../features/product/productsSlice";
import { addItem } from "../../../../features/cart/cartSlice";
import { toast } from "react-toastify";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Onsale from "../on-sale";
import { LoadDisplayContainer } from "../../../../common/fetching/style";
import { BallTriangle } from "react-loader-spinner";
import axios from "axios";
import { api_url } from "../../../../features/api_url";
import TopRated from "../top-rated";
const FeaturedProducts = () => {
  const [requiredQuantity, setRequiredQuantity] = useState(1);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();
  // const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchProductsByCategory("on sale"));
    fetchProductsByCategory();
  }, []);

  const fetchProductsByCategory = async () => {
    try {
      const response = await axios.get(`${api_url}/product-category/features`);
      console.log("category new", response.data);
      setProducts(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddToCart = (product) => {
    console.log(product, "add product to cart");
    // Dispatch the addItem action when the "Add to Cart" button is clicked
    dispatch(addItem({ ...product, requiredQuantity }));
    toast("item added to cart");
  };

  const options = {
    nav: true,
    dots: true,
    margin: 20,
    loop: true,
    autoPlay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 2,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      992: {
        items: 4,
      },
      1200: {
        items: 5,
      },
      1600: {
        items: 6,
        nav: true,
      },
    },
  };
  return (
    <div className="container-fluid">
      <div className="tab-content tab-content-carousel">
        <div
          className="tab-pane p-0 fade show active"
          id="products-featured-tab"
          role="tabpanel"
          aria-labelledby="products-featured-link"
        >
          <OwlCarousel
            className="owl-carousel owl-simple carousel-equal-height carousel-with-shadow"
            data-toggle="owl"
            items={5}
            autoplay={true}
            margin={20}
            dots={true}
            loop={true}
            {...options}
            options='{
                                "nav": true, 
                                "dots": true,
                                "margin": 20,
                                "loop": true,
                                "autoPlay": true,
				                "autoplayTimeout": 4000,
                                "responsive": {
                                    "0": {
                                        "items":2
                                    },
                                    "480": {
                                        "items":2
                                    },
                                    "768": {
                                        "items":3
                                    },
                                    "992": {
                                        "items":4
                                    },
                                    "1200": {
                                        "items":5
                                    },
                                    "1600": {
                                        "items":6,
                                        "nav": true
                                    }
                                }
                            }'
          >
            {products?.length === 0 && (
              <LoadDisplayContainer>
                <BallTriangle
                  height={100}
                  width={100}
                  radius={5}
                  color="#4fa94d"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              </LoadDisplayContainer>
            )}
            {products?.map((product) => (
              <div className="product product-11 text-center">
                <figure className="product-media">
                  <a href={`/product-detail/${product._id}`}>
                    <img
                      src={product.frontImage}
                      alt="Product image"
                      className="product-image"
                    />
                    <img
                      src={product.backImage}
                      alt="Product image"
                      className="product-image-hover"
                    />
                  </a>

                  <div className="product-action-vertical">
                    <a href="#" className="btn-product-icon btn-wishlist">
                      <span>add to wishlist</span>
                    </a>
                  </div>
                  {/* <!-- End .product-action-vertical --> */}
                </figure>
                {/* <!-- End .product-media --> */}

                <div className="product-body">
                  <h3 className="product-title">
                    <a href={`/product-detail/${product._id}`}>
                      {product.name}
                    </a>
                  </h3>
                  {/* <!-- End .product-title --> */}
                  <div className="product-price">${product.price}</div>
                  {/* <!-- End .product-price --> */}
                </div>
                {/* <!-- End .product-body --> */}
                <div className="product-action">
                  <a
                    href="#"
                    onClick={() => handleAddToCart(product)}
                    className="btn-product btn-cart"
                  >
                    <span>add to cart</span>
                  </a>
                </div>
                {/* <!-- End .product-action --> */}
              </div>
            ))}

            {/* <!-- End .product --> */}
          </OwlCarousel>
          {/* <!-- End .owl-carousel --> */}
        </div>
        {/* <!-- .End .tab-pane --> */}
        <div
          className="tab-pane p-0 fade"
          id="products-sale-tab"
          role="tabpanel"
          aria-labelledby="products-sale-link"
        >
          <Onsale />
          {/* <!-- End .owl-carousel --> */}
        </div>
        {/* <!-- .End .tab-pane --> */}
        <div
          className="tab-pane p-0 fade"
          id="products-top-tab"
          role="tabpanel"
          aria-labelledby="products-top-link"
        >
          <TopRated />
          {/* <!-- End .owl-carousel --> */}
        </div>
        {/* <!-- .End .tab-pane --> */}
      </div>
      {/* <!-- End .tab-content --> */}
    </div>
  );
};

export default FeaturedProducts;
