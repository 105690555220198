import React from "react";
import PageContent from "./page-content";

const CrowLeggings = () => {
  return (
    <>
      <link
        rel="stylesheet"
        href="assets/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css"
      />
      <link rel="stylesheet" href="assets/css/style.css" />
      <link rel="stylesheet" href="assets/css/skins/skin-demo-6.css" />
      <link rel="stylesheet" href="assets/css/demos/demo-6.css" />
      <main class="main">
        <div class="intro-slider-container">
          <div
            class="intro-slider owl-carousel owl-theme owl-nav-inside owl-light"
            data-toggle="owl"
            data-owl-options='{"dots": true, "nav": true, "loop": true, "autoplay": true, "autoplayTimeout": 6000, "responsive": {
                    "0": {
                        "items":1
                    },
                    "480": {
                        "items":1
                    },
                    "768": {
                        "items":1
                    },
                    "992": {
                        "items":1
                    },
                    "1200": {
                        "items":1,
                        "nav": true
                        }
                        }
                        }'
          >
            <div
              class="intro-slide"
              style={{
                backgroundImage:
                  "url(assets/images/demos/demo-6/slider/slide-5.jpg)",
              }}
            >
              <div class="container intro-content text-center">
                <h3 class="intro-subtitle text-white">Look Differnt with </h3>
                {/* <!-- End .h3 intro-subtitle --> */}
                <h1 class="intro-title text-white">Our Women's wears</h1>
                {/* <!-- End .intro-title --> */}

                {/* <a href="category.html" class="btn btn-outline-white-4">
                  <span>Discover More</span>
                </a> */}
              </div>
              {/* <!-- End .intro-content --> */}
            </div>
            {/* <!-- End .intro-slide --> */}

            <div
              class="intro-slide"
              style={{
                backgroundImage:
                  "url(assets/images/demos/demo-6/slider/slide-6.jpg)",
              }}
            >
              <div class="container intro-content text-center">
                <h3 class="intro-subtitle text-white">Get the best of</h3>
                {/* <!-- End .h3 intro-subtitle --> */}
                <h1 class="intro-title text-white">Our Women's wears Suits</h1>
                {/* <!-- End .intro-title --> */}

                {/* <a href="category.html" class="btn btn-outline-white-4">
                  <span>Discover More</span>
                </a> */}
              </div>
              {/* <!-- End .intro-content --> */}
            </div>
            {/* <!-- End .intro-slide --> */}
          </div>
          {/* <!-- End .intro-slider owl-carousel owl-theme --> */}

          <span class="slider-loader"></span>
          {/* <!-- End .slider-loader --> */}
        </div>
        {/* <!-- End .intro-slider-container --> */}

        <div class="mb-5"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="mb-5"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="mb-6"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="mb-2"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="container"></div>
        <PageContent />
        {/* <!-- End .container --> */}
      </main>
    </>
  );
};

export default CrowLeggings;
