import React from "react";
import { ModalContainer } from "./style";

const Modal = ({ qrCode, closeModal }) => {
  return (
    <ModalContainer>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Product Qr Code</h5>
          </div>
          <div className="modal-body custom-modal-body">
            <img src={qrCode} />
          </div>
          <div className="modal-footer">
            <a
              type="button"
              className="btn btn-secondary text-primary"
              data-bs-dismiss="modal"
              href={qrCode}
              download="qrcode.png"
            >
              Download
            </a>
            <button
              onClick={closeModal}
              type="button"
              className="btn btn-primary"
            >
              close
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default Modal;
