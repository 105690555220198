import React from "react";
import ContactPageContent from "./page-content";

const ContactUs = () => {
  return (
    <div className="main">
      <div
        class="page-header text-center"
        style={{ backgroundImage: "url('assets/images/page-header-bg.jpg')" }}
      >
        <div class="container">
          <h1 class="page-title">
            Contact us <span>Page</span>
          </h1>
        </div>
        {/* <!-- End .container --> */}
      </div>
      {/* <!-- End .page-header --> */}
      <nav aria-label="breadcrumb" class="breadcrumb-nav border-0 mb-0">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="index-25.html">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#">Pages</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Contact us 2
            </li>
          </ol>
        </div>
        {/* <!-- End .container --> */}
      </nav>
      {/* <!-- End .breadcrumb-nav --> */}
      <ContactPageContent />
    </div>
  );
};

export default ContactUs;
