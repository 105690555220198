import React, { useState } from "react";
import { NavContainer } from "./style";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../features/cart/cartSlice";

const Header = ({ handleShowMobileNav }) => {
  const dispatch = useDispatch();
  const [requiredQuantity, setRequiredQuantity] = useState(1);
  const [editedQuantity, setEditedQuantity] = useState({});
  const cartItems = useSelector((state) => state.cart.items);

  const handleRemoveFromCart = (item) => {
    dispatch(removeItem(item));
    setEditedQuantity((prev) => {
      const newEditedQuantity = { ...prev };
      delete newEditedQuantity[item._id];
      return newEditedQuantity;
    });
  };

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.requiredQuantity,
    0
  );
  console.log(cartItems.length, "cart length");
  return (
    <NavContainer class="header header-5">
      <div class="header-middle sticky-header">
        <div class="container-fluid d-flex">
          <div class="header-left header-left-custom">
            <button onClick={handleShowMobileNav} className="bars">
              {/* <span class="sr-only">Toggle mobile menu</span> */}
              <i class="icon-bars"></i>
            </button>

            <NavLink reloadDocument to="/" class="logo">
              <img
                src="/assets/images/demos/demo-5/logo.png"
                alt="Molla Logo"
                width="80"
              />
            </NavLink>

            <nav class="main-nav">
              <ul class="menu sf-arrows">
                <li class="megamenu-container active">
                  <NavLink reloadDocument to="/" class="sf-with">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink reloadDocument to="/about" class="sf-with">
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink reloadDocument to="#" class="#">
                    WPPO Collections
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink reloadDocument to="/lifting-suits">
                        Lifting Suits
                      </NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/track-suits">
                        Track Suits
                      </NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/profit-polos">
                        Profit and Polos
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to="#" class="sf-with-ul">
                    Our Brands
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink reloadDocument to="/280-degrees">
                        280 Degrees
                      </NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/varsity-jacket">
                        Varsity Jackets
                      </NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/top-leggings">
                        Crop top & Leggings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink reloadDocument to="/profit-polos-two">
                        Profit and Polos
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink reloadDocument to="/nursing-scrubs" class="sf-with">
                    Nursing Scrubs
                  </NavLink>
                </li>
                <li>
                  <NavLink reloadDocument to="/contact-us" class="sf-with">
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              {/* <!-- End .menu --> */}
            </nav>
            {/* <!-- End .main-nav --> */}
          </div>
          {/* <!-- End .header-left --> */}

          <div class="header-right header-right-custom">
            <div class="header-search header-search-custom header-search-extended header-search-visible">
              {/* <NavLink to="#" class="search-toggle" role="button">
                <i class="icon-search"></i>
              </NavLink> */}
              <form action="#" method="get">
                <div class="header-search-wrapper">
                  <label for="q" class="sr-only">
                    Search
                  </label>
                  <input
                    type="search"
                    class="form-control"
                    name="q"
                    id="q"
                    placeholder="Search product ..."
                    required
                  />
                  <button class="btn btn-primary" type="submit">
                    <i class="icon-search"></i>
                  </button>
                </div>
                {/* <!-- End .header-search-wrapper --> */}
              </form>
            </div>
            {/* <!-- End .header-search --> */}

            {/* <NavLink reloadDocument to="#" class="wishlist-link custom-wish">
              <i class="icon-heart-o"></i>
            </NavLink> */}

            <div class="dropdown cart-dropdown">
              <button
                to="#"
                class="dropdown-toggle d-flex custom-cart-items-container"
                role="button"
              >
                <i class="icon-shopping-cart"></i>
                <span class="cart-count">{cartItems?.length}</span>
              </button>

              <div class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-cart-products">
                  {cartItems?.map((items) => (
                    <div class="product">
                      <div class="product-cart-details">
                        <h4 class="product-title">
                          <NavLink to="#">{items?.name}</NavLink>
                        </h4>

                        <span class="cart-product-info">
                          <span class="cart-product-qty">
                            {items?.requiredQuantity}
                          </span>
                          x ${items?.price}
                        </span>
                      </div>
                      {/* <!-- End .product-cart-details --> */}

                      <figure class="product-image-container">
                        <NavLink to="#" class="product-image">
                          <img src={items.frontImage} alt="product" />
                        </NavLink>
                      </figure>
                      <NavLink
                        onClick={() => handleRemoveFromCart(items)}
                        to="#"
                        class="btn-remove"
                        title="Remove Product"
                      >
                        <i class="icon-close"></i>
                      </NavLink>
                    </div>
                  ))}

                  {/* <!-- End .product --> */}
                </div>
                {/* <!-- End .cart-product --> */}

                <div class="dropdown-cart-total">
                  <span>Total</span>

                  <span class="cart-total-price">${totalPrice}</span>
                </div>
                {/* <!-- End .dropdown-cart-total --> */}

                <div class="dropdown-cart-action">
                  <NavLink to="/cart" class="btn btn-primary">
                    View Cart
                  </NavLink>
                  <NavLink to="/checkout" class="btn btn-outline-primary-2">
                    <span>Checkout</span>
                    <i class="icon-long-arrow-right"></i>
                  </NavLink>
                </div>
                {/* <!-- End .dropdown-cart-total --> */}
              </div>
              {/* <!-- End .dropdown-menu --> */}
            </div>
            {/* <!-- End .cart-dropdown --> */}
          </div>
          {/* <!-- End .header-right --> */}
        </div>
        {/* <!-- End .container-fluid --> */}
      </div>
      {/* <!-- End .header-middle --> */}
    </NavContainer>
  );
};

export default Header;
