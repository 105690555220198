import Header from "./common/nav";
import HomeComp from "./components/home";
import Footer from "./common/footer";
import { Route, Routes } from "react-router-dom";
import LiftingSuits from "./components/lifting suits";
import ContactUs from "./components/contact-us";
import VarsityJacket from "./components/varsity jackets";
import TrackSuits from "./components/tract suits";
import CrowLeggings from "./components/crow and leggings";
import ProfitsPolos from "./components/profits-polos";
import NursingScrubs from "./components/nursing scrubs";
import Two80Degrees from "./components/280 degrees";
import MobileNav from "./common/nav/mobile-nav";
import { CustomBodyWrapper } from "./app.style";
import { useState } from "react";
import AboutUs from "./components/about";
import Cart from "./components/cart";
import Checkout from "./components/checkout";
import ProductDetail from "./components/product details";
import CreateProduct from "./components/create-product";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfitsPolosTwo from "./components/profit-polos-two";
import Account from "./components/dashboard";
function App() {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleHide = () => {
    setShow(false);
  };
  return (
    <CustomBodyWrapper className="page-wrapper">
      <Header handleShowMobileNav={handleShow} />
      <Routes>
        <Route path="/" element={<HomeComp />} />
        <Route path="/lifting-suits" element={<LiftingSuits />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/varsity-jacket" element={<VarsityJacket />} />
        <Route path="/track-suits" element={<TrackSuits />} />
        <Route path="/top-leggings" element={<CrowLeggings />} />
        <Route path="/profit-polos" element={<ProfitsPolos />} />
        <Route path="/profit-polos-two" element={<ProfitsPolosTwo />} />
        <Route path="/nursing-scrubs" element={<NursingScrubs />} />
        <Route path="/280-degrees" element={<Two80Degrees />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/product-detail/:productId" element={<ProductDetail />} />
        <Route path="/product-detail" element={<ProductDetail />} />
        <Route path="/secret-one" element={<CreateProduct />} />
        <Route path="/account" element={<Account />} />
      </Routes>
      <div class="mobile-menu-overlay"></div>
      {show === true && <MobileNav handleCloseBar={handleHide} />}
      <Footer />
      <ToastContainer />
    </CustomBodyWrapper>
  );
}

export default App;
