import React from "react";

const VideoBanner = () => {
  return (
    <div
      class="video-banner video-banner-bg bg-image text-center"
      style={{ backgroundImage: "url(assets/images/demos/demo-5/bg-2.jpg)" }}
    >
      <div class="container">
        <h3 class="video-banner-title h1 text-white">
          <span>Abuja 2020 </span>
          <strong>
            Para Powerlifting <i>/</i> Worldcup
          </strong>
        </h3>
        {/* <!-- End .video-banner-title --> */}
        <a
          href="https://www.youtube.com/watch?v=4q34l6I3uRQ"
          class="btn-video btn-iframe"
        >
          <i class="icon-play"></i>
        </a>
      </div>
      {/* <!-- End .container --> */}
    </div>
  );
};

export default VideoBanner;
