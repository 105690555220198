import React from "react";

const AboutUs = () => {
  return (
    <main class="main">
      <nav aria-label="breadcrumb" class="breadcrumb-nav border-0 mb-0">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="index-25.html">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#">Pages</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              About us
            </li>
          </ol>
        </div>
        {/* <!-- End .container --> */}
      </nav>
      {/* <!-- End .breadcrumb-nav --> */}
      <div class="container">
        <div
          class="page-header page-header-big text-center"
          style={{
            backgroundImage: "url('assets/images/about-header-bg.jpg')",
          }}
        >
          <h1 class="page-title text-white">
            About us<span class="text-white">Who we are</span>
          </h1>
        </div>
        {/* <!-- End .page-header --> */}
      </div>
      {/* <!-- End .container --> */}

      <div class="page-content pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 mb-3 mb-lg-0">
              <h2 class="title">About Us</h2>
              {/* <!-- End .title --> */}
              <p>
                Welcome to Limitless Identity Fashion, where we believe that
                fashion is not just about what you wear; it's a reflection of
                who you are and who you aspire to be. Our journey began with a
                passion for self-expression and the desire to provide fashion
                enthusiasts with a canvas to create their own unique identities
                through clothing.
                <br />
                <br />
                Our story is rooted in the belief that fashion has the power to
                transcend societal norms and expectations. Founded by a group of
                creative minds who shared a passion for authenticity, Limitless
                Identity Fashion was born out of a desire to break away from the
                ordinary and embrace the extraordinary. Our founders recognized
                that each person possesses an identity that knows no limits, and
                fashion should be the vehicle to express that limitless
                potential.
              </p>
            </div>
            {/* <!-- End .col-lg-6 --> */}

            <div class="col-lg-6">
              <h2 class="title">Our Vision</h2>
              {/* <!-- End .title --> */}
              <p>
                At Limitless Identity Fashion, we are committed to empowering
                individuals to express themselves boldly, without boundaries. We
                envision a world where fashion is a tool for self-discovery, a
                means of projecting confidence, and a celebration of diversity.{" "}
                <br />
                <br />
                Our philosophy is simple yet profound: Fashion should be a means
                of self-expression, a celebration of individuality, and a
                reflection of personal style. We curate collections that are as
                diverse as our customers, catering to a wide range of tastes,
                preferences, and lifestyles. From timeless classics to
                cutting-edge trends, we offer an array of options for everyone
                to explore and define their unique fashion journey.
              </p>
            </div>
            {/* <!-- End .col-lg-6 --> */}
          </div>
          {/* <!-- End .row --> */}

          <div class="mb-5"></div>
          {/* <!-- End .mb-4 --> */}
        </div>
        {/* <!-- End .container --> */}

        <div class="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 mb-3 mb-lg-0">
                <h2 class="title">Who We Are</h2>
                {/* <!-- End .title --> */}
                <p class="lead text-primary mb-3">
                  At Limitless Identity Fashion, we are not just a fashion
                  company; we are a movement, an expression, and a celebration
                  of individuality. Our brand is a tribute to the limitless
                  potential that resides within each person and the belief that
                  fashion is a powerful means of self-expression.
                </p>
                {/* <!-- End .lead text-primary --> */}
                <p class="mb-2">
                  We believe that identity is boundless and ever-evolving.
                  Fashion is the medium through which we explore, express, and
                  evolve our identities. Whether you're a trendsetter, a
                  classicist, or a blend of both, we're here to provide you with
                  a wardrobe that resonates with your inner self.{" "}
                </p>
              </div>
              {/* <!-- End .col-lg-5 --> */}

              <div class="col-lg-6 offset-lg-1">
                <div class="about-images">
                  <img
                    src="assets/images/about/img-1.jpg"
                    alt=""
                    class="about-img-front"
                  />
                  <img
                    src="assets/images/about/img-2.jpg"
                    alt=""
                    class="about-img-back"
                  />
                </div>
                {/* <!-- End .about-images --> */}
              </div>
              {/* <!-- End .col-lg-6 --> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
          {/* <!-- End .container --> */}
        </div>
        {/* <!-- End .bg-light-2 pt-6 pb-6 --> */}

        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <div class="brands-text">
                <h2 class="title">
                  At Limitless Identity Fashion, we believe in the power of
                  collaboration.
                </h2>
                {/* <!-- End .title --> */}
                <p>
                  If you are interested in becoming an affiliated partner of
                  Limitless Identity Fashion, we welcome the opportunity to
                  explore potential collaborations. Together, we can achieve
                  even greater heights and make a lasting impact on our
                  industries and communities.
                </p>
              </div>
              {/* <!-- End .brands-text --> */}
            </div>
            {/* <!-- End .col-lg-5 --> */}
            <div class="col-lg-7">
              <div class="brands-display">
                <div class="row justify-content-center">
                  <div class="col-6 col-sm-4">
                    <a href="#" class="brand">
                      <img src="assets/images/brands/1.png" alt="Brand Name" />
                    </a>
                  </div>
                  {/* <!-- End .col-sm-4 --> */}

                  <div class="col-6 col-sm-4">
                    <a href="#" class="brand">
                      <img src="assets/images/brands/2.png" alt="Brand Name" />
                    </a>
                  </div>
                  {/* <!-- End .col-sm-4 --> */}

                  <div class="col-6 col-sm-4">
                    <a href="#" class="brand">
                      <img src="assets/images/brands/3.png" alt="Brand Name" />
                    </a>
                  </div>
                  {/* <!-- End .col-sm-4 --> */}

                  <div class="col-6 col-sm-4">
                    <a href="#" class="brand">
                      <img src="assets/images/brands/4.png" alt="Brand Name" />
                    </a>
                  </div>
                  {/* <!-- End .col-sm-4 --> */}

                  <div class="col-6 col-sm-4">
                    <a href="#" class="brand">
                      <img src="assets/images/brands/5.png" alt="Brand Name" />
                    </a>
                  </div>
                  {/* <!-- End .col-sm-4 --> */}

                  <div class="col-6 col-sm-4">
                    <a href="#" class="brand">
                      <img src="assets/images/brands/6.png" alt="Brand Name" />
                    </a>
                  </div>
                  {/* <!-- End .col-sm-4 --> */}

                  <div class="col-6 col-sm-4">
                    <a href="#" class="brand">
                      <img src="assets/images/brands/7.png" alt="Brand Name" />
                    </a>
                  </div>
                  {/* <!-- End .col-sm-4 --> */}

                  <div class="col-6 col-sm-4">
                    <a href="#" class="brand">
                      <img src="assets/images/brands/8.png" alt="Brand Name" />
                    </a>
                  </div>
                  {/* <!-- End .col-sm-4 --> */}

                  <div class="col-6 col-sm-4">
                    <a href="#" class="brand">
                      <img src="assets/images/brands/9.png" alt="Brand Name" />
                    </a>
                  </div>
                  {/* <!-- End .col-sm-4 --> */}
                </div>
                {/* <!-- End .row --> */}
              </div>
              {/* <!-- End .brands-display --> */}
            </div>
            {/* <!-- End .col-lg-7 --> */}
          </div>
          {/* <!-- End .row --> */}

          <hr class="mt-4 mb-6" />
        </div>
        {/* <!-- End .container --> */}

        <div class="mb-2"></div>
        {/* <!-- End .mb-2 --> */}

        <div class="about-testimonials bg-light-2 pt-6 pb-6">
          <div class="container">
            <h2 class="title text-center mb-3">What Customer Say About Us</h2>
            {/* <!-- End .title text-center --> */}

            <div
              class="owl-carousel owl-simple owl-testimonials-photo"
              data-toggle="owl"
              data-owl-options='{
                                "nav": true, 
                                "dots": true,
                                "margin": 20,
                                "loop": true,
                                "autoplay": true,
				                "autoplayTimeout": 4000,
                                "responsive": {
                                    "1200": {
                                        "nav": true
                                    }
                                }
                            }'
            >
              <blockquote class="testimonial text-center">
                <img
                  src="/assets/images/testimonials/user-1 - Copy.jpg"
                  alt="user"
                />
                <p>
                  “Limitless Identity Fashion is my go-to fashion destination.
                  Their diverse collections allow me to express myself
                  authentically. From elegant classics to bold statements, they
                  have it all. Quality, style, and inclusivity define them. A
                  brand that truly celebrates individuality. ”
                </p>
                <cite>
                  Sarah T.
                  <span>Practising Nurse</span>
                </cite>
              </blockquote>
              {/* <!-- End .testimonial --> */}

              <blockquote class="testimonial text-center">
                <img src="/assets/images/testimonials/user-2.jpeg" alt="user" />
                <p>
                  “I can't express how much I appreciate Limitless Identity
                  Fashion's commitment to sustainability. It's rare to find a
                  brand that combines fashion-forward designs with a dedication
                  to the environment. I feel good about wearing their clothing
                  in more ways than one.”
                </p>
                <cite>
                  Micheal L.
                  <span>Athlethe</span>
                </cite>
              </blockquote>
              {/* <!-- End .testimonial --> */}

              <blockquote class="testimonial text-center">
                <img src="assets/images/testimonials/user-1.jpg" alt="user" />
                <p>
                  “ I've been a loyal customer for years, and Limitless Identity
                  Fashion never disappoints. Their pieces are not just clothing;
                  they're an extension of my personality. The quality is
                  outstanding, and their dedication to representing diverse
                  identities resonates with me. ”
                </p>
                <cite>
                  Ethan M.
                  <span>Health Worker</span>
                </cite>
              </blockquote>
              {/* <!-- End .testimonial --> */}

              <blockquote class="testimonial text-center">
                <img
                  src="/assets/images/testimonials/user-1 - Copy (2).jpg"
                  alt="user"
                />
                <p>
                  “I'm constantly impressed by the versatility of Limitless
                  Identity Fashion's collections. From casual wear to formal
                  attire, I always find something that suits my style. Their
                  customer service is top-notch too, making every shopping
                  experience enjoyable.”
                </p>

                <cite>
                  Victoria Ventura
                  <span>Customer</span>
                </cite>
              </blockquote>
              {/* <!-- End .testimonial --> */}
            </div>
            {/* <!-- End .testimonials-slider owl-carousel --> */}
          </div>
          {/* <!-- End .container --> */}
        </div>
        {/* <!-- End .bg-light-2 pt-5 pb-6 --> */}
      </div>
      {/* <!-- End .page-content --> */}
    </main>
  );
};

export default AboutUs;
