import React, { useState } from "react";
import { createProduct } from "../../features/product/productsSlice";
import axios from "axios";
import { toast } from "react-toastify";

const Create = () => {
  const [formData, setFormData] = useState({
    category: "",
    name: "",
    description: "",
    quantity: "",
    price: "",
    discount: 0,
    frontImage: [],
    backImage: [],
    additionalImageSamples: [],
  });
  const [isCreating, setIsCreating] = useState(false);
  const categories = [
    "lifting suits",
    "track suits",
    "profits and polos",
    "280 degrees",
    "varsity jackets",
    "crow top and leggings",
    "nursing scrubs",
    "all-home",
    "home-clothing",
    "home-shoes",
    "profit and polos two",
    "home-accessories",
    "features",
    "on sale",
    "top-rated",
  ];
  const handleChange = (event) => {
    const { name, value, type, files } = event.target;
    const fieldValue = type === "file" ? files[0] : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fieldValue,
    }));
  };
  console.log("handleChange", formData);

  const createProduct = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    const data = new FormData();
    data.append("category", formData.category);
    data.append("name", formData.name);
    data.append("price", formData.price);
    data.append("description", formData.description);
    data.append("quantity", formData.quantity);
    data.append("discount", formData.discount);
    data.append("amount", formData.amount);
    data.append("frontImage", formData.frontImage);
    data.append("backImage", formData.backImage);
    data.append("additionalImageSamples", formData.additionalImageSamples);
    const response = await axios.post(
      "https://lif-backend.onrender.com/create-product",
      data
    );
    console.log(response, "created successfully");
    if (response.status === 200) {
      toast("product created successfully");
      setIsCreating(false);
    }
  };
  return (
    <div class="col-lg-8">
      <h2 class="checkout-title">Create Product</h2>
      {/* <!-- End .checkout-title --> */}
      <div class="row">
        <div class="col-sm-6">
          <label>Product Name *</label>
          <input
            name="name"
            onChange={handleChange}
            type="text"
            class="form-control"
            required
          />
        </div>
        {/* <!-- End .col-sm-6 --> */}

        <div class="col-sm-6">
          <label>Product Categor *</label>
          {/* <input type="text" class="form-control" required /> */}
          <select name="category" onChange={handleChange} class="form-control">
            {categories?.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        </div>
        {/* <!-- End .col-sm-6 --> */}
      </div>
      {/* <!-- End .row --> */}

      <div class="row">
        <div class="col-sm-6">
          <label>Price *</label>
          <input
            name="price"
            onChange={handleChange}
            type="text"
            class="form-control"
            required
          />
        </div>
        {/* <!-- End .col-sm-6 --> */}

        <div class="col-sm-6">
          <label>Discount *</label>
          <input
            name="discount"
            onChange={handleChange}
            type="text"
            class="form-control"
            required
          />
        </div>
        {/* <!-- End .col-sm-6 --> */}
      </div>
      {/* <!-- End .row --> */}

      <label>Description *</label>
      <textarea
        name="description"
        onChange={handleChange}
        type="text"
        class="form-control"
        placeholder="Best description of product..."
        required
      />

      <div class="row">
        <div class="col-sm-6">
          <label>Quantity *</label>
          <input
            name="quantity"
            onChange={handleChange}
            type="text"
            class="form-control"
            required
          />
        </div>
        {/* <!-- End .col-sm-6 --> */}

        <div class="col-sm-6">
          <label>Front Product Image *</label>
          <input
            name="frontImage"
            onChange={handleChange}
            type="file"
            class="form-control"
            required
          />
        </div>
        {/* <!-- End .col-sm-6 --> */}
      </div>
      {/* <!-- End .row --> */}

      <div class="row">
        <div class="col-sm-6">
          <label>Back Product Image *</label>
          <input
            name="backImage"
            onChange={handleChange}
            type="file"
            class="form-control"
            required
          />
        </div>
        {/* <!-- End .col-sm-6 --> */}

        <div class="col-sm-6">
          <label>Additional Product Image *</label>
          <input
            type="file"
            name="additionalImageSamples"
            onChange={handleChange}
            class="form-control"
            required
            multiple
          />
        </div>
        {/* <!-- End .col-sm-6 --> */}
      </div>
      {/* <!-- End .row --> */}
      <button
        onClick={createProduct}
        class="btn btn-outline-primary-2 btn-order btn-block col-4 m-auto"
      >
        {isCreating === false ? "Create Product" : "Creating....."}
      </button>
    </div>
  );
};

export default Create;
