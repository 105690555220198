import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducts,
  fetchProductsByCategory,
} from "../../../features/product/productsSlice";
import { addItem } from "../../../features/cart/cartSlice";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../../features/api_url";
import { BallTriangle } from "react-loader-spinner";
import { LoadDisplayContainer } from "../../../common/fetching/style";
const NewArrival = () => {
  const [requiredQuantity, setRequiredQuantity] = useState(1);
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchProductsByCategory("on sale"));
    fetchDefaultAllProducts();
  }, []);

  const fetchDefaultAllProducts = async () => {
    try {
      const response = await axios.get(`${api_url}/product-category/all-home`);
      console.log("category new", response.data);
      setProducts(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductsByCategory = async (category) => {
    try {
      const response = await axios.get(
        `${api_url}/product-category/${category}`
      );
      console.log("category new", response.data);
      setProducts(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  // const products = useSelector((state) => {
  //   return state.products;
  // });
  console.log(products, "featured products");

  // const dispatch = useDispatch();

  const handleAddToCart = (product) => {
    console.log(product, "add product to cart");
    // Dispatch the addItem action when the "Add to Cart" button is clicked
    dispatch(addItem({ ...product, requiredQuantity }));
    toast("item added to cart");
  };
  return (
    <div class="container pt-6 new-arrivals">
      <div class="heading heading-center mb-3">
        <h2 class="title">New Arrivals</h2>
        {/* <!-- End .title --> */}

        <ul class="nav nav-pills justify-content-center" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="new-all-link"
              data-toggle="tab"
              href="#new-all-tab"
              role="tab"
              onClick={fetchDefaultAllProducts}
              aria-controls="new-all-tab"
              aria-selected="true"
            >
              All
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="new-cloth-link"
              data-toggle="tab"
              href="#new-cloth-tab"
              role="tab"
              onClick={() => fetchProductsByCategory("home-clothing")}
              aria-controls="new-cloth-tab"
              aria-selected="false"
            >
              Clothing
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="new-shoes-link"
              data-toggle="tab"
              href="#new-shoes-tab"
              role="tab"
              onClick={() => fetchProductsByCategory("home-shoes")}
              aria-controls="new-shoes-tab"
              aria-selected="false"
            >
              Shoes & Boots
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="new-access-link"
              data-toggle="tab"
              href="#new-access-tab"
              role="tab"
              onClick={() => fetchProductsByCategory("home-accessories")}
              aria-controls="new-access-tab"
              aria-selected="false"
            >
              Accessories
            </a>
          </li>
        </ul>
      </div>
      {/* <!-- End .heading --> */}

      <div class="tab-content">
        <div
          class="tab-pane p-0 fade show active"
          id="new-all-tab"
          role="tabpanel"
          aria-labelledby="new-all-link"
        >
          <div class="products">
            <div class="row justify-content-center">
              {products.length === 0 && (
                <LoadDisplayContainer>
                  <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#4fa94d"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                </LoadDisplayContainer>
              )}
              {products?.map((product) => (
                <div class="col-6 col-md-4 col-lg-3">
                  <div class="product product-2">
                    <figure class="product-media">
                      <a href={`/product-detail/${product._id}`}>
                        <img
                          src={product?.frontImage}
                          alt="Product image"
                          class="product-image"
                        />
                        <img
                          src={product?.backImage}
                          alt="Product image"
                          class="product-image-hover"
                        />
                      </a>

                      <div class="product-action-vertical">
                        <a
                          href="#"
                          class="btn-product-icon btn-wishlist"
                          title="Add to wishlist"
                        >
                          <span>add to wishlist</span>
                        </a>
                      </div>
                      {/* <!-- End .product-action --> */}

                      <div class="product-action product-action-transparent">
                        <a
                          href="#"
                          onClick={() => handleAddToCart(product)}
                          class="btn-product btn-cart"
                        >
                          <span>add to cart</span>
                        </a>
                      </div>
                      {/* <!-- End .product-action --> */}
                    </figure>
                    {/* <!-- End .product-media --> */}

                    <div class="product-body">
                      <div class="product-cat">
                        <a href="#">{product.category}</a>
                      </div>
                      {/* <!-- End .product-cat --> */}
                      <h3 class="product-title">
                        <a href={`/product-detail/${product._id}`}>
                          {product.name}
                        </a>
                      </h3>
                      {/* <!-- End .product-title --> */}
                      <div class="product-price">${product.price}</div>
                      {/* <!-- End .product-price --> */}
                      <div class="product-nav product-nav-dots">
                        <a
                          href="#"
                          class="active"
                          style={{ background: "#e5dcb1" }}
                        >
                          <span class="sr-only">Color name</span>
                        </a>
                        <a href="#" style={{ background: "#b9cbd8" }}>
                          <span class="sr-only">Color name</span>
                        </a>
                      </div>
                      {/* <!-- End .product-nav --> */}
                    </div>
                    {/* <!-- End .product-body --> */}
                  </div>
                  {/* <!-- End .product --> */}
                </div>
              ))}
              {/* <!-- End .col-sm-6 col-md-4 col-lg-3 --> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
          {/* <!-- End .products --> */}
        </div>
        {/* <!-- .End .tab-pane --> */}
        <div
          class="tab-pane p-0 fade"
          id="new-cloth-tab"
          role="tabpanel"
          aria-labelledby="new-cloth-link"
        >
          <div class="products">
            <div class="row justify-content-center">
              {products.length === 0 && (
                <LoadDisplayContainer>
                  <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#4fa94d"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                </LoadDisplayContainer>
              )}
              {products?.map((product) => (
                <div class="col-6 col-md-4 col-lg-3">
                  <div class="product product-2">
                    <figure class="product-media">
                      <a href={`/product-detail/${product._id}`}>
                        <img
                          src={product?.frontImage}
                          alt="Product image"
                          class="product-image"
                        />
                        <img
                          src={product?.backImage}
                          alt="Product image"
                          class="product-image-hover"
                        />
                      </a>

                      <div class="product-action-vertical">
                        <a
                          href="#"
                          class="btn-product-icon btn-wishlist"
                          title="Add to wishlist"
                        >
                          <span>add to wishlist</span>
                        </a>
                      </div>
                      {/* <!-- End .product-action --> */}

                      <div class="product-action product-action-transparent">
                        <a
                          href="#"
                          onClick={() => handleAddToCart(product)}
                          class="btn-product btn-cart"
                        >
                          <span>add to cart</span>
                        </a>
                      </div>
                      {/* <!-- End .product-action --> */}
                    </figure>
                    {/* <!-- End .product-media --> */}

                    <div class="product-body">
                      <div class="product-cat">
                        <a href="#">Clothing</a>
                      </div>
                      {/* <!-- End .product-cat --> */}
                      <h3 class="product-title">
                        <a href={`/product-detail/${product._id}`}>
                          {product.name}
                        </a>
                      </h3>
                      {/* <!-- End .product-title --> */}
                      <div class="product-price">${product.price}</div>
                      {/* <!-- End .product-price --> */}
                      <div class="product-nav product-nav-dots">
                        <a
                          href="#"
                          class="active"
                          style={{ background: "#e5dcb1" }}
                        >
                          <span class="sr-only">Color name</span>
                        </a>
                        <a href="#" style={{ background: "#b9cbd8" }}>
                          <span class="sr-only">Color name</span>
                        </a>
                      </div>
                      {/* <!-- End .product-nav --> */}
                    </div>
                    {/* <!-- End .product-body --> */}
                  </div>
                  {/* <!-- End .product --> */}
                </div>
              ))}
              {/* <!-- End .col-sm-6 col-md-4 col-lg-3 --> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
          {/* <!-- End .products --> */}
        </div>
        {/* <!-- .End .tab-pane --> */}
        <div
          class="tab-pane p-0 fade"
          id="new-shoes-tab"
          role="tabpanel"
          aria-labelledby="new-shoes-link"
        >
          <div class="products">
            <div class="row justify-content-center">
              {products.length === 0 && (
                <LoadDisplayContainer>
                  <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#4fa94d"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                </LoadDisplayContainer>
              )}
              {products?.map((product) => (
                <div class="col-6 col-md-4 col-lg-3">
                  <div class="product product-2">
                    <figure class="product-media">
                      <a href={`/product-detail/${product._id}`}>
                        <img
                          src={product?.frontImage}
                          alt="Product image"
                          class="product-image"
                        />
                        <img
                          src={product?.backImage}
                          alt="Product image"
                          class="product-image-hover"
                        />
                      </a>

                      <div class="product-action-vertical">
                        <a
                          href="#"
                          class="btn-product-icon btn-wishlist"
                          title="Add to wishlist"
                        >
                          <span>add to wishlist</span>
                        </a>
                      </div>
                      {/* <!-- End .product-action --> */}

                      <div class="product-action product-action-transparent">
                        <a
                          href="#"
                          onClick={() => handleAddToCart(product)}
                          class="btn-product btn-cart"
                        >
                          <span>add to cart</span>
                        </a>
                      </div>
                      {/* <!-- End .product-action --> */}
                    </figure>
                    {/* <!-- End .product-media --> */}

                    <div class="product-body">
                      <div class="product-cat">
                        <a href="#">Clothing</a>
                      </div>
                      {/* <!-- End .product-cat --> */}
                      <h3 class="product-title">
                        <a href={`/product-detail/${product._id}`}>
                          {product.name}
                        </a>
                      </h3>
                      {/* <!-- End .product-title --> */}
                      <div class="product-price">${product.price}</div>
                      {/* <!-- End .product-price --> */}
                      <div class="product-nav product-nav-dots">
                        <a
                          href="#"
                          class="active"
                          style={{ background: "#e5dcb1" }}
                        >
                          <span class="sr-only">Color name</span>
                        </a>
                        <a href="#" style={{ background: "#b9cbd8" }}>
                          <span class="sr-only">Color name</span>
                        </a>
                      </div>
                      {/* <!-- End .product-nav --> */}
                    </div>
                    {/* <!-- End .product-body --> */}
                  </div>
                  {/* <!-- End .product --> */}
                </div>
              ))}
              {/* <!-- End .col-sm-6 col-md-4 col-lg-3 --> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
          {/* <!-- End .products --> */}
        </div>
        {/* <!-- .End .tab-pane --> */}
        <div
          class="tab-pane p-0 fade"
          id="new-access-tab"
          role="tabpanel"
          aria-labelledby="new-access-link"
        >
          <div class="products">
            <div class="row justify-content-center">
              {products.length === 0 && (
                <LoadDisplayContainer>
                  <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#4fa94d"
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle=""
                    visible={true}
                  />
                </LoadDisplayContainer>
              )}
              {products?.map((product) => (
                <div class="col-6 col-md-4 col-lg-3">
                  <div class="product product-2">
                    <figure class="product-media">
                      <a href={`/product-detail/${product._id}`}>
                        <img
                          src={product?.frontImage}
                          alt="Product image"
                          class="product-image"
                        />
                        <img
                          src={product?.backImage}
                          alt="Product image"
                          class="product-image-hover"
                        />
                      </a>

                      <div class="product-action-vertical">
                        <a
                          href="#"
                          class="btn-product-icon btn-wishlist"
                          title="Add to wishlist"
                        >
                          <span>add to wishlist</span>
                        </a>
                      </div>
                      {/* <!-- End .product-action --> */}

                      <div class="product-action product-action-transparent">
                        <a
                          href="#"
                          onClick={() => handleAddToCart(product)}
                          class="btn-product btn-cart"
                        >
                          <span>add to cart</span>
                        </a>
                      </div>
                      {/* <!-- End .product-action --> */}
                    </figure>
                    {/* <!-- End .product-media --> */}

                    <div class="product-body">
                      <div class="product-cat">
                        <a href="#">Clothing</a>
                      </div>
                      {/* <!-- End .product-cat --> */}
                      <h3 class="product-title">
                        <a href={`/product-detail/${product._id}`}>
                          {product.name}
                        </a>
                      </h3>
                      {/* <!-- End .product-title --> */}
                      <div class="product-price">${product.price}</div>
                      {/* <!-- End .product-price --> */}
                      <div class="product-nav product-nav-dots">
                        <a
                          href="#"
                          class="active"
                          style={{ background: "#e5dcb1" }}
                        >
                          <span class="sr-only">Color name</span>
                        </a>
                        <a href="#" style={{ background: "#b9cbd8" }}>
                          <span class="sr-only">Color name</span>
                        </a>
                      </div>
                      {/* <!-- End .product-nav --> */}
                    </div>
                    {/* <!-- End .product-body --> */}
                  </div>
                  {/* <!-- End .product --> */}
                </div>
              ))}
              {/* <!-- End .col-sm-6 col-md-4 col-lg-3 --> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
          {/* <!-- End .products --> */}
        </div>
        {/* <!-- .End .tab-pane --> */}
      </div>
      {/* <!-- End .tab-content --> */}

      <div class="more-container text-center mt-1 mb-3">
        <a
          href="/nursing-scrubs"
          class="btn btn-outline-primary-2 btn-round btn-more"
        >
          View more
        </a>
      </div>
      {/* <!-- End .more-container --> */}
    </div>
  );
};

export default NewArrival;
