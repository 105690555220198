import React from "react";
import Aside from "./aside";
import Create from "./create";

const CreateProduct = () => {
  return (
    <div>
      <div className="container">
        <form action="#">
          <div class="row">
            <Aside />
            {/* <!-- End .col-lg-3 --> */}
            <Create />
            {/* <!-- End .col-lg-9 --> */}
          </div>
          {/* <!-- End .row --> */}
        </form>
      </div>
    </div>
  );
};

export default CreateProduct;
