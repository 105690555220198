import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed !important;
  height: 100vh !important;
  width: 100% !important;
  background-color: rgba(128, 128, 128, 0.288);
  z-index: 9 !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .custom-modal-body {
    img {
      width: 100%;
    }
    .bankDetails {
      /* background-color: red; */
      p {
        width: 95%;
        margin: 1rem auto;
      }
      .details {
        padding: 10px;
        table {
          border: 1px solid;
          width: 100%;
          tr {
            border: 1px solid;
            td {
              border: 1px solid;
              padding: 5px;
            }
            .bold {
              font-weight: 600 !important;
              font-size: 14px;
            }
          }
        }
        /* align-items: center; */
      }
    }
  }
`;
