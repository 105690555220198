import React from "react";

const LiftingSuitsSlider = () => {
  return (
    <div class="intro-slider-container mb-3 mb-lg-5">
      <div
        class="intro-slider owl-carousel owl-theme owl-nav-inside owl-light"
        data-toggle="owl"
        data-owl-options='{"dots": true, "nav": true, "loop": true, "autoplay": true, "autoplayTimeout": 6000, "responsive": {
                    "0": {
                        "items":1
                    },
                    "480": {
                        "items":1
                    },
                    "768": {
                        "items":1
                    },
                    "992": {
                        "items":1
                    },
                    "1200": {
                        "items":1,
                        "nav": true
                        }
                        }
                        }'
      >
        <div
          class="intro-slide"
          style={{
            backgroundImage:
              "url(assets/images/demos/demo-18/slider/slide-1.jpg)",
          }}
        >
          <div class="container">
            <div class="intro-content text-center">
              <h3 class="intro-subtitle cross-txt text-primary">WPPO PICKS</h3>
              {/* <!-- End .h3 intro-subtitle --> */}
              <h1 class="intro-title text-white">Country Bulk Sale</h1>
              {/* <!-- End .intro-title --> */}
              <div class="intro-text text-white">up to 30% off</div>
              {/* <!-- End .intro-text --> */}
              <div class="intro-action cross-txt">
                <a href="#" class="btn btn-outline-white">
                  <span>Go Limitless</span>
                </a>
              </div>
            </div>
          </div>
          {/* <!-- End .intro-content --> */}
        </div>
        {/* <!-- End .intro-slide --> */}

        <div
          class="intro-slide"
          style={{
            backgroundImage:
              "url(assets/images/demos/demo-18/slider/slide-2.jpg)",
          }}
        >
          <div class="container">
            <div class="intro-content text-center">
              <h3 class="intro-subtitle cross-txt text-primary">
                SPORTS WEARS
              </h3>
              {/* <!-- End .h3 intro-subtitle --> */}
              <h1 class="intro-title text-white">Athletic Accessories</h1>
              {/* <!-- End .intro-title --> */}
              <div class="intro-text text-white">up to 20% off</div>
              {/* <!-- End .intro-text --> */}
              <div class="intro-action cross-txt">
                <a href="#" class="btn btn-outline-white">
                  <span>Go Limitless</span>
                </a>
              </div>
            </div>
          </div>
          {/* <!-- End .intro-content --> */}
        </div>
        {/* <!-- End .intro-slide --> */}

        <div
          class="intro-slide"
          style={{
            backgroundImage:
              "url(assets/images/demos/demo-18/slider/slide-4.jpg)",
          }}
        >
          <div class="container">
            <div class="intro-content text-center">
              <h3 class="intro-subtitle text-primary cross-txt">
                Women's Accessories
              </h3>
              {/* <!-- End .h3 intro-subtitle --> */}
              <h1 class="intro-title text-white">Save up to</h1>
              {/* <!-- End .intro-title --> */}
              <div class="intro-text text-white">30-50% off</div>
              {/* <!-- End .intro-text --> */}
              <div class="intro-action cross-txt">
                <a href="#" class="btn btn-outline-white">
                  <span>Go Limitless</span>
                </a>
              </div>
            </div>
          </div>
          {/* <!-- End .intro-content --> */}
        </div>
        {/* <!-- End .intro-slide --> */}
      </div>
      {/* <!-- End .intro-slider owl-carousel owl-simple --> */}

      <span class="slider-loader text-white"></span>
      {/* <!-- End .slider-loader --> */}
    </div>
  );
};

export default LiftingSuitsSlider;
