import axios from "axios";
import { api_url } from "../api_url";

// fetch products
const getProducts = async () => {
  try {
    const response = await axios.get(`${api_url}/products`);
    console.log("klsdklsdkldlklk", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// fetch products
const getProductsByCategory = async (categoryName) => {
  try {
    const response = await axios.get(
      `${api_url}/product-category/${categoryName}`
    );
    console.log("category", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// fetch products
const getProductById = async (productId) => {
  try {
    const response = await axios.get(`${api_url}/product/${productId}`);
    console.log("klsdklsdkldlklk", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const createProduct = async (userData) => {
  const userAuthToken = JSON.parse(localStorage.getItem("fixa-mkt-vendor"))[
    "token"
  ];
  const response = await axios.post(`${api_url}/create-product`, userData, {
    headers: {
      Authorization: `Bearer ${userAuthToken}`,
    },
  });

  // if (response) {
  //   localStorage.setItem("mami-user", JSON.stringify(response.data));
  // }
  return response.data;
};

const addReview = async ({ review, productId }) => {
  // console.log(productId, review);
  const userAuthToken = JSON.parse(localStorage.getItem("fixa-mkt-user"))[
    "token"
  ];
  // console.log(userAuthToken, "user token");
  const response = await axios.post(`${api_url}/reviews/${productId}`, review, {
    headers: {
      Authorization: `Bearer ${userAuthToken}`,
      ContentType: "application/json",
    },
  });

  // if (response) {
  //   localStorage.setItem("mami-user", JSON.stringify(response.data));
  // }
  console.log(response.data);
  return response.data;
};

const productService = {
  getProducts,
  getProductById,
  createProduct,
  addReview,
  getProductsByCategory,
};

export default productService;
