import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducts,
  fetchProductsByCategory,
} from "../../../../features/product/productsSlice";
import FeaturedTab from "./featured-tab";
import FeaturedProducts from "./featured";

const FeaturedIndex = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProductsByCategory("features"));
    // setProductsState(products?.items);
  }, []);
  const products = useSelector((state) => {
    return state.products;
  });
  console.log(products, "featured products");
  return (
    <>
      <FeaturedTab />
      <FeaturedProducts products={products} />
    </>
  );
};

export default FeaturedIndex;
