import React from "react";
import { StyledMapContainer } from "./style";

const ContactPageContent = () => {
  return (
    <div class="page-content">
      <div id="map" class="mb-5">
        <StyledMapContainer class="mapouter">
          <div class="gmap_canvas">
            <iframe
              class="gmap_iframe"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=40 Meadowcreek Road, Caledon, canada ON L7C1C4&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
            <a href="https://connectionsgame.org/">Connections Unlimited</a>
          </div>
        </StyledMapContainer>
      </div>
      {/* <!-- End #map --> */}
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="contact-box text-center">
              <h3>Office</h3>

              <address>
                40 Meadowcreek Road, <br />
                Caledon ON L7C1C4
              </address>
            </div>
            {/* <!-- End .contact-box --> */}
          </div>
          {/* <!-- End .col-md-4 --> */}

          <div class="col-md-4">
            <div class="contact-box text-center">
              <h3>Start a Conversation</h3>

              <div>
                <a href="mailto:#">qi@limitlessidentityfashion.com</a>
              </div>
              <div>
                <a href="tel:+1(365)7737390">+1 (365) 773-7390</a>,
              </div>
            </div>
            {/* <!-- End .contact-box --> */}
          </div>
          {/* <!-- End .col-md-4 --> */}

          <div class="col-md-4">
            <div class="contact-box text-center">
              <h3>Social</h3>

              <div class="social-icons social-icons-color justify-content-center">
                <a
                  href="#"
                  class="social-icon social-facebook"
                  title="Facebook"
                  target="_blank"
                >
                  <i class="icon-facebook-f"></i>
                </a>
                <a
                  href="#"
                  class="social-icon social-twitter"
                  title="Twitter"
                  target="_blank"
                >
                  <i class="icon-twitter"></i>
                </a>
                <a
                  href="#"
                  class="social-icon social-instagram"
                  title="Instagram"
                  target="_blank"
                >
                  <i class="icon-instagram"></i>
                </a>
                <a
                  href="#"
                  class="social-icon social-youtube"
                  title="Youtube"
                  target="_blank"
                >
                  <i class="icon-youtube"></i>
                </a>
                <a
                  href="#"
                  class="social-icon social-pinterest"
                  title="Pinterest"
                  target="_blank"
                >
                  <i class="icon-pinterest"></i>
                </a>
              </div>
              {/* <!-- End .soial-icons --> */}
            </div>
            {/* <!-- End .contact-box --> */}
          </div>
          {/* <!-- End .col-md-4 --> */}
        </div>
        {/* <!-- End .row --> */}

        <hr class="mt-3 mb-5 mt-md-1" />
        <div class="touch-container row justify-content-center">
          <div class="col-md-9 col-lg-7">
            <div class="text-center">
              <h2 class="title mb-1">Get In Touch</h2>
              {/* <!-- End .title mb-2 --> */}
              <p class="lead text-primary">
                We collaborate with ambitious brands and people; we’d love to
                build something great together.
              </p>
              {/* <!-- End .lead text-primary --> */}
              <p class="mb-3">
                Our dedicated customer support team is ready to assist you with
                any inquiries or concerns you may have. Whether you need
                assistance with an order, have product questions, or require any
                other assistance, we're just a message or call away.
              </p>
            </div>
            {/* <!-- End .text-center --> */}

            <form action="#" class="contact-form mb-2">
              <div class="row">
                <div class="col-sm-4">
                  <label for="cname" class="sr-only">
                    Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="cname"
                    placeholder="Name *"
                    required
                  />
                </div>
                {/* <!-- End .col-sm-4 --> */}

                <div class="col-sm-4">
                  <label for="cemail" class="sr-only">
                    Name
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="cemail"
                    placeholder="Email *"
                    required
                  />
                </div>
                {/* <!-- End .col-sm-4 --> */}

                <div class="col-sm-4">
                  <label for="cphone" class="sr-only">
                    Phone
                  </label>
                  <input
                    type="tel"
                    class="form-control"
                    id="cphone"
                    placeholder="Phone"
                  />
                </div>
                {/* <!-- End .col-sm-4 --> */}
              </div>
              {/* <!-- End .row --> */}

              <label for="csubject" class="sr-only">
                Subject
              </label>
              <input
                type="text"
                class="form-control"
                id="csubject"
                placeholder="Subject"
              />

              <label for="cmessage" class="sr-only">
                Message
              </label>
              <textarea
                class="form-control"
                cols="30"
                rows="4"
                id="cmessage"
                required
                placeholder="Message *"
              ></textarea>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-outline-primary-2 btn-minwidth-sm"
                >
                  <span>SUBMIT</span>
                  <i class="icon-long-arrow-right"></i>
                </button>
              </div>
              {/* <!-- End .text-center --> */}
            </form>
            {/* <!-- End .contact-form --> */}
          </div>
          {/* <!-- End .col-md-9 col-lg-7 --> */}
        </div>
        {/* <!-- End .row --> */}
      </div>
      {/* <!-- End .container --> */}
    </div>
  );
};

export default ContactPageContent;
