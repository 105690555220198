import styled from "styled-components";

export const ZoomOut = styled.figure`
  .zoomable-image {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
  }

  /* Add a hover effect */
  .image-container:hover img {
    transform: scale(1.1); /* Adjust the scale factor for the zoom effect */
  }
`;

export const ImageZoomContainer = styled.div`
  background-color: pink;
  /* padding: 1px !important; */
  .imageRootContainer {
    position: relative !important;
    .largeImageContainer {
      /* position: absolute !important; */
      /* right: 0 !important; */
      /* left: 0; */
      background-color: red !important;
    }
  }
`;
