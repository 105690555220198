import React from "react";

const LiftSuitBanner = () => {
  return (
    <div class="container banners">
      <div class="row">
        <div class="col-lg-6">
          <div class="banner banner-hover">
            <a href="#">
              <img
                src="assets/images/demos/demo-18/banners/banner-1.jpg"
                alt="Banner"
              />
            </a>

            <div class="banner-content">
              <h3 class="banner-title text-white">
                <a href="#">Lifting Suit wears</a>
              </h3>
              {/* <!-- End .banner-title --> */}
              {/* <a href="#" class="banner-link">
                Shop Now <i class="icon-long-arrow-right"></i>
              </a> */}
            </div>
            {/* <!-- End .banner-content --> */}
          </div>
          {/* <!-- End .banner --> */}
        </div>
        {/* <!-- End .col-lg-6 --> */}

        <div class="col-sm-6 col-lg-3">
          <div class="banner banner-hover">
            <a href="#">
              <img
                src="assets/images/demos/demo-18/banners/banner-2.jpg"
                alt="Banner"
              />
            </a>

            <div class="banner-content">
              <h3 class="banner-title text-white">
                <a href="#">Track Suits</a>
              </h3>
              {/* <!-- End .banner-title --> */}
              {/* <a href="#" class="banner-link">
                Shop Now <i class="icon-long-arrow-right"></i>
              </a> */}
            </div>
            {/* <!-- End .banner-content --> */}
          </div>
          {/* <!-- End .banner --> */}
        </div>
        {/* <!-- End .col-sm-6 --> */}

        <div class="col-sm-6 col-lg-3">
          <div class="banner banner-hover">
            <a href="#">
              <img
                src="assets/images/demos/demo-18/banners/banner-3.jpg"
                alt="Banner"
              />
            </a>

            <div class="banner-content">
              <h3 class="banner-title text-white">
                <a href="#">String Bags</a>
              </h3>
              {/* <!-- End .banner-title --> */}
              {/* <a href="#" class="banner-link">
                Shop Now <i class="icon-long-arrow-right"></i>
              </a> */}
            </div>
            {/* <!-- End .banner-content --> */}
          </div>
          {/* <!-- End .banner --> */}

          <div class="banner banner-hover">
            <a href="#">
              <img
                src="assets/images/demos/demo-18/banners/banner-4.jpg"
                alt="Banner"
              />
            </a>

            <div class="banner-content">
              <h3 class="banner-title text-white">
                <a href="#">Accessories</a>
              </h3>
              {/* <!-- End .banner-title --> */}
              {/* <a href="#" class="banner-link">
                Shop Now <i class="icon-long-arrow-right"></i>
              </a> */}
            </div>
            {/* <!-- End .banner-content --> */}
          </div>
          {/* <!-- End .banner --> */}
        </div>
        {/* <!-- End .col-sm-6 --> */}
      </div>
      {/* <!-- End .row --> */}
    </div>
  );
};

export default LiftSuitBanner;
