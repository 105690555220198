import React from "react";
import PageContent from "./page-content";

const NursingScrubs = () => {
  return (
    <>
      <link rel="stylesheet" href="assets/css/style.css" />
      <link rel="stylesheet" href="assets/css/skins/skin-demo-6.css" />
      <link rel="stylesheet" href="assets/css/demos/demo-6.css" />
      <main class="main">
        <div class="intro-slider-container">
          <div
            class="intro-slider owl-carousel owl-theme owl-nav-inside owl-light"
            data-toggle="owl"
            data-owl-options='{"dots": true, "nav": true, "loop": true, "autoplay": true, "autoplayTimeout": 6000, "responsive": {
                    "0": {
                        "items":1
                    },
                    "480": {
                        "items":1
                    },
                    "768": {
                        "items":1
                    },
                    "992": {
                        "items":1
                    },
                    "1200": {
                        "items":1,
                        "nav": true
                        }
                        }
                        }'
          >
            <div
              class="intro-slide"
              style={{
                backgroundImage:
                  "url(assets/images/demos/demo-6/slider/slide-3.jpg)",
              }}
            >
              <div class="container intro-content text-center">
                <h3 class="intro-subtitle text-white">Stand Out in our </h3>
                {/* <!-- End .h3 intro-subtitle --> */}
                <h1 class="intro-title text-white">Nursing Scrub</h1>
                {/* <!-- End .intro-title --> */}

                {/* <a href="category.html" class="btn btn-outline-white-4">
                  <span>Discover More</span>
                </a> */}
              </div>
              {/* <!-- End .intro-content --> */}
            </div>
            {/* <!-- End .intro-slide --> */}

            <div
              class="intro-slide"
              style={{
                backgroundImage:
                  "url(assets/images/demos/demo-6/slider/slide-4.jpg)",
              }}
            >
              <div class="container intro-content text-center">
                <h3 class="intro-subtitle text-white">Trend along with</h3>
                {/* <!-- End .h3 intro-subtitle --> */}
                <h1 class="intro-title text-white">Our New Scrubs</h1>
                {/* <!-- End .intro-title --> */}

                {/* <a href="category.html" class="btn btn-outline-white-4">
                  <span>Discover More</span>
                </a> */}
              </div>
              {/* <!-- End .intro-content --> */}
            </div>
            {/* <!-- End .intro-slide --> */}
          </div>
          {/* <!-- End .intro-slider owl-carousel owl-theme --> */}

          <span class="slider-loader"></span>
          {/* <!-- End .slider-loader --> */}
        </div>
        {/* <!-- End .intro-slider-container --> */}

        <div class="pt-2 pb-3">
          <div class="container">
            <div class="row">
              <div class="col-sm-6">
                <div class="banner banner-overlay">
                  <a href="#">
                    <img
                      src="assets/images/demos/demo-6/banners/banner-4.jpg"
                      alt="Banner"
                    />
                  </a>

                  <div class="banner-content banner-content-center">
                    <h4 class="banner-subtitle text-white">
                      <a href="#">New for</a>
                    </h4>
                    {/* <!-- End .banner-subtitle --> */}
                    <h3 class="banner-title text-white">
                      <a href="#">
                        <strong>Nurses</strong>
                      </a>
                    </h3>
                    {/* <!-- End .banner-title --> */}
                    <a
                      href="#"
                      class="btn btn-outline-white banner-link underline"
                    >
                      Shop Now
                    </a>
                  </div>
                  {/* <!-- End .banner-content --> */}
                </div>
                {/* <!-- End .banner --> */}
              </div>
              {/* <!-- End .col-sm-6 --> */}

              <div class="col-sm-6">
                <div class="banner banner-overlay">
                  <a href="#">
                    <img
                      src="assets/images/demos/demo-6/banners/banner-5.jpg"
                      alt="Banner"
                    />
                  </a>

                  <div class="banner-content banner-content-center">
                    <h4 class="banner-subtitle text-white">
                      <a href="#">New</a>
                    </h4>
                    {/* <!-- End .banner-subtitle --> */}
                    <h3 class="banner-title text-white">
                      <a href="#">
                        <strong>Collection</strong>
                      </a>
                    </h3>
                    {/* <!-- End .banner-title --> */}
                    <a
                      href="#"
                      class="btn btn-outline-white banner-link underline"
                    >
                      Shop Now
                    </a>
                  </div>
                  {/* <!-- End .banner-content --> */}
                </div>
                {/* <!-- End .banner --> */}
              </div>
              {/* <!-- End .col-sm-6 --> */}
            </div>
            {/* <!-- End .row --> */}
            <hr class="mt-0 mb-0" />
          </div>
          {/* <!-- End .container --> */}
        </div>
        {/* <!-- End .bg-gray --> */}

        <div class="mb-5"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="mb-5"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="mb-6"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="mb-2"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="container"></div>
        {/* <!-- End .container --> */}

        <div class="touch-container row justify-content-center">
          <div class="col-md-9 col-lg-7">
            <div class="text-center">
              <h2 class="title mb-1">Full Customization</h2>
              {/* <!-- End .title mb-2 --> */}
              <p class="lead text-primary">
                We offer full customization options, including the addition of
                your company logo and name, if desired.
              </p>
              {/* <!-- End .lead text-primary --> */}
            </div>
            {/* <!-- End .text-center --> */}
          </div>
          {/* <!-- End .col-md-9 col-lg-7 --> */}
        </div>

        <PageContent />
      </main>
    </>
  );
};

export default NursingScrubs;
