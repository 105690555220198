import React from "react";
import PageContent from "./page-content";
import Slider from "./slider";

const ProfitsPolosTwo = () => {
  return (
    <>
      <link rel="stylesheet" href="/assets/css/style.css" />
      <link rel="stylesheet" href="/assets/css/skins/skin-demo-21.css" />
      <link rel="stylesheet" href="/assets/css/demos/demo-21.css" />
      <main class="main">
        <div class="intro-slider-container mb-5">
          <div
            class="intro-slider owl-carousel owl-theme owl-nav-inside owl-light"
            data-toggle="owl"
            data-owl-options='{"dots": true, "nav": true, "loop": true, "autoplay": true, "autoplayTimeout": 6000, "responsive": {
                    "0": {
                        "items":1
                    },
                    "480": {
                        "items":1
                    },
                    "768": {
                        "items":1
                    },
                    "992": {
                        "items":1
                    },
                    "1200": {
                        "items":1,
                        "nav": true
                        }
                        }
                        }'
          >
            <div
              class="intro-slide"
              style={{
                backgroundImage:
                  "url(assets/images/demos/demo-21/slider/slide-1.png)",
              }}
            >
              <div class="container intro-content">
                <div class="row">
                  <div class="intro">
                    <div class="title">
                      <h3>MEN & WOMEN'S</h3>
                    </div>
                    <div class="content">
                      <h3>LIFTING &</h3>
                      <h3>TRAINING SHIRTS</h3>
                    </div>
                    <div class="price">
                      <h3>SAVE UP TO 30%</h3>
                    </div>
                    {/* <div class="action">
                      <a href="category.html" class="btn btn-primary">
                        <span>DISCOVER NOW</span>
                      </a>
                    </div> */}
                  </div>
                </div>
                {/* <!-- End .row --> */}
              </div>
              {/* <!-- End .intro-content --> */}
            </div>
            {/* <!-- End .intro-slide --> */}

            <div
              class="intro-slide"
              style={{
                backgroundImage:
                  "url(assets/images/demos/demo-21/slider/slide-2.jpg)",
              }}
            >
              <div class="container intro-content">
                <div class="row">
                  <div class="intro">
                    <div class="title">
                      <h3 class="darkWhite">DEAL OF THE DAY</h3>
                    </div>
                    <div class="content">
                      <h3>
                        IT'S TIME TO UPGRADE
                        <br />
                        YOUR SPORTING KIT
                      </h3>
                    </div>
                    <div class="price">
                      <h3 class="darkWhite">SAVE UP TO 15%</h3>
                    </div>
                    {/* <div class="action">
                      <a href="category.html" class="btn btn-primary">
                        <span>DISCOVER NOW</span>
                      </a>
                    </div> */}
                  </div>
                </div>
                {/* <!-- End .row --> */}
              </div>
              {/* <!-- End .intro-content --> */}
            </div>
            {/* <!-- End .intro-slide --> */}
          </div>
          {/* <!-- End .intro-slider owl-carousel owl-simple --> */}

          <span class="slider-loader"></span>
          {/* <!-- End .slider-loader --> */}
        </div>
        {/* <!-- End .intro-slider-container --> */}
        <div class="container banner-container">
          <div class="col-lg-4 col-md-8 col-sm-10 col-12 col-pd1">
            <a href="category.html">
              <img src="assets/images/demos/demo-21/banner/banner-1.jpg" />
            </a>
            <div class="banner-content">
              <div class="title">
                <a href="#">
                  <h3 class="darkWhite">It's a lifestyle.</h3>
                </a>
              </div>
              <div class="content">
                <a href="category.html">
                  <h3>Top Quality</h3>
                </a>
                <a href="category.html">
                  <h3>profit</h3>
                </a>
              </div>
              {/* <div class="action">
                <a href="category.html" class="btn btn-demoprimary">
                  <span>SHOP NOW</span>
                  <i class="icon-long-arrow-right"></i>
                </a>
              </div> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
          <div class="col-lg-4 col-md-8 col-sm-10 col-12 col-pd1">
            <a href="category.html">
              <img src="assets/images/demos/demo-21/banner/banner-2.jpg" />
            </a>
            <div class="banner-content">
              <div class="title">
                <a href="category.html">
                  <h3 class="darkWhite">Hype Your Next Event </h3>
                </a>
              </div>
              <div class="content">
                <a href="category.html">
                  <h3>NEW Event</h3>
                </a>
                <a href="category.html">
                  <h3>NEW wears</h3>
                </a>
              </div>
              {/* <div class="action">
                <a href="category.html" class="btn btn-demoprimary">
                  <span>DISCOVER NOW</span>
                  <i class="icon-long-arrow-right"></i>
                </a>
              </div> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
          <div class="col-lg-4 col-md-8 col-sm-10 col-12 col-pd1">
            <a href="category.html">
              <img src="assets/images/demos/demo-21/banner/banner-3.jpg" />
            </a>
            <div class="banner-content">
              <div class="title">
                <a href="category.html">
                  <h3 class="darkWhite">Season Sale</h3>
                </a>
              </div>
              <div class="content">
                <a href="category.html">
                  <h3>Coach Wear</h3>
                </a>
                <a href="category.html">
                  <h3>Sales</h3>
                </a>
              </div>
              {/* <div class="action">
                <a href="category.html" class="btn btn-demoprimary">
                  <span>SHOP NOW</span>
                  <i class="icon-long-arrow-right"></i>
                </a>
              </div> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
        </div>

        <PageContent />
      </main>
    </>
  );
};

export default ProfitsPolosTwo;
