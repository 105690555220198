import React from "react";

const Special = () => {
  return (
    <div class="pt-6 pb-6" style={{ backgroundColor: "#fff" }}>
      <div class="container">
        <div class="banner-set">
          <div class="row">
            <div class="col-lg-6">
              <div class="banner-set-content text-center">
                <div class="set-content-wrapper">
                  <h4>Special</h4>
                  <h2>Refine Your Style.</h2>

                  <p>
                    Get on our exclusive email list and be the first to hear
                    about sales, coupons, new arrivals and more!{" "}
                  </p>

                  <div class="banner-set-products">
                    <div class="row">
                      <div class="products">
                        <div class="col-6">
                          <div class="product product-2 text-center">
                            <figure class="product-media">
                              <a href="/nursing-scrubs">
                                <img
                                  src="assets/images/demos/demo-5/products/product-13.jpg"
                                  alt="Product image"
                                  class="product-image"
                                />
                              </a>
                            </figure>
                            {/* <!-- End .product-media --> */}

                            <div class="product-body">
                              <h3 class="product-title">
                                <a href="/nursing-scrubs">Pattern Scrub</a>
                              </h3>
                              {/* <!-- End .product-title --> */}
                              <div class="product-price">$50</div>
                              {/* <!-- End .product-price --> */}
                            </div>
                            {/* <!-- End .product-body --> */}
                          </div>
                          {/* <!-- End .product --> */}
                        </div>
                        {/* <!-- End .col-sm-6 --> */}

                        <div class="col-6">
                          <div class="product product-2 text-center">
                            <figure class="product-media">
                              <a href="/nursing-scrubs">
                                <img
                                  src="assets/images/demos/demo-5/products/product-14.jpg"
                                  alt="Product image"
                                  class="product-image"
                                />
                              </a>
                            </figure>
                            {/* <!-- End .product-media --> */}

                            <div class="product-body">
                              <h3 class="product-title">
                                <a href="/nursing-scrubs">Scrub Pants</a>
                              </h3>
                              {/* <!-- End .product-title --> */}
                              <div class="product-price">$50</div>
                              {/* <!-- End .product-price --> */}
                            </div>
                            {/* <!-- End .product-body --> */}
                          </div>
                          {/* <!-- End .product --> */}
                        </div>
                        {/* <!-- End .col-sm-6 --> */}
                      </div>
                    </div>
                    {/* <!-- End .row --> */}
                  </div>
                  {/* <!-- End .banner-set-products --> */}
                </div>
                {/* <!-- End .set-content-wrapper --> */}
              </div>
              {/* <!-- End .banner-set-content --> */}
            </div>
            {/* <!-- End .col-lg-6 --> */}
            <div class="col-lg-6">
              <div class="banner-set-image banner-border-hover">
                <a href="#">
                  <img
                    src="assets/images/demos/demo-5/banners/banner-4.jpg"
                    alt="banner"
                  />
                </a>
                <div class="banner-content">
                  <h3 class="banner-title">
                    <a href="#">
                      <span>
                        Nurse Scrubs and
                        <br />
                        trendy key pieces.
                      </span>
                    </a>
                  </h3>
                  {/* <!-- End .banner-title --> */}
                  <h4 class="banner-subtitle">in this look</h4>
                  {/* <!-- End .banner-subtitle --> */}
                  <h4 class="banner-detail">
                    • Fine Linen Material <br />• Linen-blended matching pants
                  </h4>
                  <h4 class="banner-price">$100.00 - $150.00</h4>
                  <a
                    href="/nursing-scrubs"
                    class="btn btn-outline-primary-2 banner-link"
                  >
                    buy all
                  </a>
                </div>
                {/* <!-- End .banner-content --> */}
              </div>
              {/* <!-- End .banner-set-image --> */}
            </div>
            {/* <!-- End .col-lg-6 --> */}
          </div>
          {/* <!-- End .row --> */}
        </div>
        {/* <!-- End .banner-set --> */}
      </div>
      {/* <!-- End .container --> */}
    </div>
  );
};

export default Special;
