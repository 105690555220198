import styled from "styled-components";

export const NavContainer = styled.div`
  /* background-color: purple; */
  .header-left-custom {
    width: 67%;
    li {
      /* background-color: blue; */
      /* padding: 0 !important; */
      a {
        font-size: 12px;
      }
    }
  }
  .bars {
    display: none !important;
    @media (max-width: 990px) {
      display: block !important;
      border: none;
      background-color: transparent !important;
      font-size: 18px;
    }
  }
  .header-right-custom {
    width: 30%;
    .header-search-custom {
      margin: 0;
      /* width: 70% !important; */
    }
    .custom-cart-items-container {
      /* background-color: orange; */
      display: flex !important;
      border: none;
      background-color: transparent;
    }
  }
`;

export const MobileNavContainer = styled.div`
  display: none;

  @media (max-width: 990px) {
    background-color: purple;
    z-index: 9;
    width: 100% !important;
    height: 100vh;
    position: fixed !important;
    padding: 0 10px;
    right: 0 !important;
    left: 0 !important;
    display: block;
  }
`;
