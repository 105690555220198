import React from "react";

const Customizeable = () => {
  return (
    <div class="container">
      <div class="banner-group">
        <div class="row">
          <div class="col-md-6">
            <div class="banner banner-border">
              <a href="#">
                <img
                  src="assets/images/demos/demo-5/banners/banner-1.jpg"
                  alt="Banner"
                />
              </a>

              <div class="banner-content">
                <h4 class="banner-subtitle">
                  <a href="#">Trending now</a>
                </h4>
                {/* <!-- End .banner-subtitle --> */}
                <h3 class="banner-title">
                  <a href="#">
                    <span>
                      Customizable
                      <br />
                      Nurse Scrubs
                    </span>
                  </a>
                </h3>
                {/* <!-- End .banner-title --> */}
                <a
                  href="/nursing-scrubs"
                  class="btn btn-outline-primary-2 banner-link"
                >
                  Order Now<i class="icon-long-arrow-right"></i>
                </a>
              </div>
              {/* <!-- End .banner-content --> */}
            </div>
            {/* <!-- End .banner --> */}
          </div>
          {/* <!-- End .col-md-6--> */}

          <div class="col-md-6">
            <div class="banner banner-border-hover">
              <a href="#">
                <img
                  src="assets/images/demos/demo-5/banners/banner-2.jpg"
                  alt="Banner"
                />
              </a>

              <div class="banner-content">
                <h4 class="banner-subtitle">
                  <a href="#">Limited brand only.</a>
                </h4>
                {/* <!-- End .banner-subtitle --> */}
                <h3 class="banner-title">
                  <a href="#">
                    <span>
                      Customizable
                      <br />
                      Varsity Jackets
                    </span>
                  </a>
                </h3>
                {/* <!-- End .banner-title --> */}
                <a
                  href="/varsity-jacket"
                  class="btn btn-outline-primary-2 banner-link"
                >
                  Shop Now<i class="icon-long-arrow-right"></i>
                </a>
              </div>
              {/* <!-- End .banner-content --> */}
            </div>
            {/* <!-- End .banner --> */}

            <div class="banner banner-border-hover">
              <a href="#">
                <img
                  src="assets/images/demos/demo-5/banners/banner-3.jpg"
                  alt="Banner"
                />
              </a>

              <div class="banner-content">
                <h4 class="banner-subtitle">
                  <a href="#">Working together</a>
                </h4>
                {/* <!-- End .banner-subtitle --> */}
                <h3 class="banner-title">
                  <a href="#">
                    <span>
                      Personalized <br />
                      Branding
                    </span>
                  </a>
                </h3>
                {/* <!-- End .banner-title --> */}
                <a
                  href="/280-degrees"
                  class="btn btn-outline-primary-2 banner-link"
                >
                  Start Now<i class="icon-long-arrow-right"></i>
                </a>
              </div>
              {/* <!-- End .banner-content --> */}
            </div>
            {/* <!-- End .banner --> */}
          </div>
          {/* <!-- End .col-md-6 --> */}
        </div>
        {/* <!-- End .row --> */}
      </div>
      {/* <!-- End .banner-group --> */}
    </div>
  );
};

export default Customizeable;
