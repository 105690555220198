import React from "react";
import PageContent from "./page-content";

const Two80Degrees = () => {
  return (
    <>
      <link rel="stylesheet" href="assets/css/style.css" />
      <link rel="stylesheet" href="assets/css/skins/skin-demo-2.css" />
      <link rel="stylesheet" href="assets/css/demos/demo-2.css" />
      <main class="main">
        <div class="intro-slider-container">
          <div
            class="intro-slider owl-carousel owl-theme owl-nav-inside owl-light"
            data-toggle="owl"
            data-owl-options='{"dots": true, "nav": true, "loop": true, "autoplay": true, "autoplayTimeout": 6000, "responsive": {
                    "0": {
                        "items":1
                    },
                    "480": {
                        "items":1
                    },
                    "768": {
                        "items":1
                    },
                    "992": {
                        "items":1
                    },
                    "1200": {
                        "items":1,
                        "nav": true
                        }
                        }
                        }'
          >
            <div
              class="intro-slide"
              style={{
                backgroundImage:
                  "url(assets/images/demos/demo-2/slider/slide-1.jpg)",
              }}
            >
              <div class="container intro-content">
                <h3 class="intro-subtitle">280 Degrees </h3>
                {/* <!-- End .h3 intro-subtitle --> */}
                <h1 class="intro-title">
                  Find Comfort <br />
                  That Suits You.
                </h1>
                {/* <!-- End .intro-title --> */}

                {/* <a href="category.html" class="btn btn-primary">
                  <span>Shop Now</span>
                  <i class="icon-long-arrow-right"></i>
                </a> */}
              </div>
              {/* <!-- End .container intro-content --> */}
            </div>
            {/* <!-- End .intro-slide --> */}

            <div
              class="intro-slide"
              style={{
                backgroundImage:
                  "url(assets/images/demos/demo-2/slider/slide-2.jpg)",
              }}
            >
              <div class="container intro-content">
                <h3 class="intro-subtitle">Deals and Promotions</h3>
                {/* <!-- End .h3 intro-subtitle --> */}
                <h1 class="intro-title">
                  Fashion <br />
                  Redefined <br />
                  <span class="text-primary">
                    <sup></sup>
                  </span>
                </h1>
                {/* <!-- End .intro-title --> */}

                {/* <a href="category.html" class="btn btn-primary">
                  <span>Shop Now</span>
                  <i class="icon-long-arrow-right"></i>
                </a> */}
              </div>
              {/* <!-- End .container intro-content --> */}
            </div>
            {/* <!-- End .intro-slide --> */}
          </div>
          {/* <!-- End .owl-carousel owl-simple --> */}

          <span class="slider-loader text-white"></span>
          {/* <!-- End .slider-loader --> */}
        </div>
        {/* <!-- End .intro-slider-container --> */}

        <div class="mb-3 mb-lg-5"></div>
        {/* <!-- End .mb-3 mb-lg-5 --> */}

        <div class="banner-group">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-5">
                <div class="banner banner-large banner-overlay banner-overlay-light">
                  <a href="#">
                    <img
                      src="assets/images/demos/demo-2/banners/banner-1.jpg"
                      alt="Banner"
                    />
                  </a>

                  <div class="banner-content banner-content-top">
                    <h4 class="banner-subtitle">Clearence</h4>
                    {/* <!-- End .banner-subtitle --> */}
                    <h3 class="banner-title">Varsity Jackets</h3>
                    {/* <!-- End .banner-title --> */}

                    {/* <a href="#" class="btn btn-outline-gray banner-link">
                      Shop Now<i class="icon-long-arrow-right"></i>
                    </a> */}
                  </div>
                  {/* <!-- End .banner-content --> */}
                </div>
                {/* <!-- End .banner --> */}
              </div>
              {/* <!-- End .col-lg-5 --> */}

              <div class="col-md-6 col-lg-3">
                <div class="banner banner-overlay">
                  <a href="#">
                    <img
                      src="assets/images/demos/demo-2/banners/banner-2.jpg"
                      alt="Banner"
                    />
                  </a>

                  <div class="banner-content banner-content-bottom">
                    <h4 class="banner-subtitle text-grey">On Sale</h4>
                    {/* <!-- End .banner-subtitle --> */}
                    <h3 class="banner-title text-white">
                      Longsleeve <br />
                      Bodyhugs
                    </h3>
                    {/* <!-- End .banner-title --> */}

                    {/* <a href="#" class="btn btn-outline-white banner-link">
                      Discover Now<i class="icon-long-arrow-right"></i>
                    </a> */}
                  </div>
                  {/* <!-- End .banner-content --> */}
                </div>
                {/* <!-- End .banner --> */}
              </div>
              {/* <!-- End .col-lg-3 --> */}

              <div class="col-md-6 col-lg-4">
                <div class="banner banner-overlay">
                  <a href="#">
                    <img
                      src="assets/images/demos/demo-2/banners/banner-3.jpg"
                      alt="Banner"
                    />
                  </a>

                  <div class="banner-content banner-content-top">
                    <h4 class="banner-subtitle text-grey">New Arrivals</h4>
                    {/* <!-- End .banner-subtitle --> */}
                    <h3 class="banner-title text-white">
                      Longsleeve <br />
                      with Pockets
                    </h3>
                    {/* <!-- End .banner-title --> */}
                    {/* <a href="#" class="btn btn-outline-white banner-link">
                      Discover Now<i class="icon-long-arrow-right"></i>
                    </a> */}
                  </div>
                  {/* <!-- End .banner-content --> */}
                </div>
                {/* <!-- End .banner --> */}

                <div class="banner banner-overlay banner-overlay-light">
                  <a href="#">
                    <img
                      src="assets/images/demos/demo-2/banners/banner-4.jpg"
                      alt="Banner"
                    />
                  </a>

                  <div class="banner-content banner-content-top">
                    <h4 class="banner-subtitle">On Sale</h4>
                    {/* <!-- End .banner-subtitle --> */}
                    <h3 class="banner-title text-white">Customizables</h3>
                    {/* <!-- End .banner-title --> */}

                    {/* <a href="#" class="btn btn-outline-gray banner-link">
                      Shop Now<i class="icon-long-arrow-right"></i>
                    </a> */}
                  </div>
                  {/* <!-- End .banner-content --> */}
                </div>
                {/* <!-- End .banner --> */}
              </div>
              {/* <!-- End .col-lg-4 --> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
          {/* <!-- End .container --> */}
        </div>
        {/* <!-- End .banner-group --> */}

        <div class="mb-3"></div>
        {/* <!-- End .mb-6 --> */}

        <div class="mb-5"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="mb-6"></div>
        {/* <!-- End .mb-6 --> */}

        <div class="container">
          <hr class="mt-1 mb-6" />
        </div>
        <PageContent />
        {/* <!-- End .container --> */}
      </main>
    </>
  );
};

export default Two80Degrees;
