import React from "react";

const ProductTable = () => {
  return (
    <div className="row">
      <div className="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Simple Editable table</h4>
            <h6 class="card-subtitle">
              Just click on word which you want to change and enter
            </h6>
            <div class="table-responsive">
              <table
                id="mainTable"
                class="table editable-table table-bordered table-striped m-b-0"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Category</th>
                    <th>Product Description</th>
                    <th>Front Image</th>
                    <th>Back Image</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input placeholder="Car" />
                    </td>
                    <td>100</td>
                    <td>200</td>
                    <td>0</td>
                    <td>
                      <div className="product-image">
                        <input
                          type="file"
                          id="frontImage"
                          style={{ display: "none" }}
                        />
                        <label for="frontImage">front image</label>
                      </div>
                    </td>
                    <td>
                      <div className="product-image">
                        <input
                          type="file"
                          id="backImage"
                          style={{ display: "none" }}
                        />
                        <label for="backImage">back image</label>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <button>update</button>
                        <button>delete</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>
                      <strong>TOTAL</strong>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTable;
