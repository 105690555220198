import React from "react";
import LiftingSuitsSlider from "./slider";
import LiftSuitBanner from "./banner";
import PageContent from "./page-content";

const LiftingSuits = () => {
  return (
    <>
      <link rel="stylesheet" href="assets/css/style.css" />
      <link rel="stylesheet" href="assets/css/demos/demo-18.css" />
      <LiftingSuitsSlider />
      <LiftSuitBanner />
      <PageContent />
    </>
  );
};

export default LiftingSuits;
