import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const HomeSlider = () => {
  return (
    <div class="intro-slider-container mb-0">
      <div
        class="intro-slider owl-carousel owl-theme owl-nav-inside owl-light"
        data-toggle="owl"
        data-owl-options='{"dots": true, "nav": true, "loop": true, "autoplay": true, "responsive": {
                    "0": {
                        "items":1
                    },
                    "480": {
                        "items":1
                    },
                    "768": {
                        "items":1
                    },
                    "992": {
                        "items":1
                    },
                    "1200": {
                        "items":1,
                        "nav": true
                        }
                        }
                        }'
      >
        <div
          class="intro-slide"
          style={{
            backgroundImage:
              "url(assets/images/demos/demo-5/slider/slide-1.jpg)",
          }}
        >
          <div class="container intro-content text-center">
            <h3 class="intro-subtitle text-white">Don’t Miss</h3>
            {/* <!-- End .h3 intro-subtitle --> */}
            <h1 class="intro-title text-white">Mystery Deals</h1>
            {/* <!-- End .intro-title --> */}
            <div class="intro-text text-white">Online Only</div>
            {/* <!-- End .intro-text --> */}
            <a href="/profit-polos-two" class="btn btn-primary">
              Discover NOW
            </a>
          </div>
          {/* <!-- End .intro-content --> */}
        </div>
        {/* <!-- End .intro-slide --> */}

        <div
          class="intro-slide"
          style={{
            backgroundImage:
              "url(assets/images/demos/demo-5/slider/slide-2.jpg)",
          }}
        >
          <div class="container intro-content text-center">
            <h3 class="intro-subtitle text-white">Explore Our</h3>
            {/* <!-- End .h3 intro-subtitle --> */}
            <h1 class="intro-title text-white">Customized Wears</h1>
            {/* <!-- End .intro-title --> */}
            <div class="intro-text text-white">Unisex</div>
            {/* <!-- End .intro-text --> */}
            <a href="/track-suits" class="btn btn-primary">
              Discover NOW
            </a>
          </div>
          {/* <!-- End .intro-content --> */}
        </div>
        {/* <!-- End .intro-slide --> */}

        <div
          class="intro-slide"
          style={{
            backgroundImage:
              "url(assets/images/demos/demo-5/slider/slide-3.jpg)",
          }}
        >
          <div class="container intro-content text-center">
            <h3 class="intro-subtitle text-white">Sports Accessories</h3>
            {/* <!-- End .h3 intro-subtitle --> */}
            <h1 class="intro-title text-white">For limitless</h1>
            {/* <!-- End .intro-title --> */}
            <div class="intro-text text-white">Athlethes</div>
            {/* <!-- End .intro-text --> */}
            <a href="/lifting-suits" class="btn btn-primary">
              Discover NOW
            </a>
          </div>
          {/* <!-- End .intro-content --> */}
        </div>
        {/* <!-- End .intro-slide --> */}

        <div
          class="intro-slide"
          style={{
            backgroundImage:
              "url(assets/images/demos/demo-5/slider/slide-4.jpg)",
          }}
        >
          <div class="container intro-content text-center">
            <h3 class="intro-subtitle text-white">Go Limitless</h3>
            {/* <!-- End .h3 intro-subtitle --> */}
            <h1 class="intro-title text-white">And triumph</h1>
            {/* <!-- End .intro-title --> */}
            <div class="intro-text text-white">On our sports wears</div>
            {/* <!-- End .intro-text --> */}
            <a href="/profit-polos" class="btn btn-primary">
              Discover NOW
            </a>
          </div>
          {/* <!-- End .intro-content --> */}
        </div>
        {/* <!-- End .intro-slide --> */}
      </div>
      {/* <!-- End .intro-slider owl-carousel owl-theme --> */}

      <span class="slider-loader text-white"></span>
      {/* <!-- End .slider-loader --> */}
    </div>
  );
};

export default HomeSlider;
