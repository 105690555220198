import React from "react";
import PageContent from "./page-content";
const VarsityJacket = () => {
  return (
    <>
      <head>
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link
          rel="stylesheet"
          href="assets/css/plugins/owl-carousel/owl.carousel.css"
        />
        <link
          rel="stylesheet"
          href="assets/css/plugins/magnific-popup/magnific-popup.css"
        />
        <link
          rel="stylesheet"
          href="/assets/css/plugins/jquery.countdown.css"
        />
        <link rel="stylesheet" href="/assets/css/style.css" />
        <link rel="stylesheet" href="/assets/css/skins/skin-demo-8.css" />
        <link rel="stylesheet" href="/assets/css/demos/demo-8.css" />
      </head>

      <main class="main">
        <div class="intro-slider-container">
          <div
            class="intro-slider owl-carousel owl-theme owl-nav-inside owl-light mb-0"
            data-toggle="owl"
            data-owl-options='{
                        "dots": true,
                        "nav": false, 
                        "responsive": {
                            "1200": {
                                "nav": true,
                                "dots": false
                            }
                        }
                    }'
          >
            <div
              class="intro-slide"
              style={{
                backgroundImage:
                  "url(assets/images/demos/demo-8/slider/slide-1.jpg)",
              }}
            >
              <div class="container intro-content text-left">
                <h3 class="intro-subtitle">Customizable deal *</h3>
                {/* <!-- End .h3 intro-subtitle --> */}
                <h1 class="intro-title">
                  Varsity
                  <br />
                  <strong>Jackets</strong>
                </h1>
                {/* <!-- End .intro-title --> */}
                <h3 class="intro-subtitle">Up to 30% off</h3>
                {/* <!-- End .h3 intro-subtitle --> */}

                {/* <a href="category.html" class="btn">
                  <span>SHOP NOW</span>
                  <i class="icon-long-arrow-right"></i>
                </a> */}
              </div>
              {/* <!-- End .intro-content --> */}
              <img
                class="position-right"
                src="assets/images/demos/demo-8/slider/img-1.png"
              />
            </div>
            {/* <!-- End .intro-slide --> */}
          </div>
          {/* <!-- End .intro-slider owl-carousel owl-simple --> */}

          <span class="slider-loader"></span>
          {/* <!-- End .slider-loader --> */}
        </div>
        {/* <!-- End .intro-slider-container --> */}

        <div class="pt-2 pb-2">
          <div class="container brands">
            <div class="banner-group">
              <div class="row">
                <div class="col-sm-6 col-lg-4">
                  <div class="banner banner-overlay">
                    <a href="#">
                      <img
                        src="assets/images/demos/demo-8/banners/banner-1.jpg"
                        alt="Banner"
                      />
                    </a>

                    <div class="banner-content">
                      <h4 class="banner-subtitle">
                        <a href="#">Full Customization</a>
                      </h4>
                      {/* <!-- End .banner-subtitle --> */}
                      <h3 class="banner-title">
                        <a href="#">
                          <strong>
                            For Men & <br />
                            Women
                          </strong>{" "}
                          <br />
                          up to 30% off
                        </a>
                      </h3>
                      {/* <!-- End .banner-title --> */}
                      {/* <a href="#" class="btn btn-outline-white banner-link">
                        Shop Now <i class="icon-long-arrow-right"></i>
                      </a> */}
                    </div>
                    {/* <!-- End .banner-content --> */}
                  </div>
                  {/* <!-- End .banner --> */}
                </div>
                {/* <!-- End .col-lg-4 --> */}

                <div class="col-sm-6 col-lg-4">
                  <div class="banner banner-overlay">
                    <a href="#">
                      <img
                        src="assets/images/demos/demo-8/banners/banner-2.jpg"
                        alt="Banner"
                      />
                    </a>

                    <div class="banner-content">
                      <h4 class="banner-subtitle">
                        <a href="#">Full Customization</a>
                      </h4>
                      {/* <!-- End .banner-subtitle --> */}
                      <h3 class="banner-title">
                        <a href="#">
                          <strong>
                            Kiddies & <br />
                            Infants
                          </strong>{" "}
                          <br />
                          20 -50% off
                        </a>
                      </h3>
                      {/* <!-- End .banner-title --> */}
                      {/* <a href="#" class="btn btn-outline-white banner-link">
                        Shop Now <i class="icon-long-arrow-right"></i>
                      </a> */}
                    </div>
                    {/* <!-- End .banner-content --> */}
                  </div>
                  {/* <!-- End .banner --> */}
                </div>
                {/* <!-- End .col-lg-4 --> */}

                <div class="col-sm-6 col-lg-4 d-none d-lg-block">
                  <div class="banner banner-overlay">
                    <a href="#">
                      <img
                        src="assets/images/demos/demo-8/banners/banner-3.jpg"
                        alt="Banner"
                      />
                    </a>

                    <div class="banner-content">
                      <h4 class="banner-subtitle">
                        <a href="#">Full Customization</a>
                      </h4>
                      {/* <!-- End .banner-subtitle --> */}
                      <h3 class="banner-title">
                        <a href="#">
                          <strong>
                            Adults <br />
                            Yeams{" "}
                          </strong>{" "}
                          <br />
                          20 - 50% off
                        </a>
                      </h3>
                      {/* <!-- End .banner-title --> */}
                      {/* <a href="#" class="btn btn-outline-white banner-link">
                        Shop Now <i class="icon-long-arrow-right"></i>
                      </a> */}
                    </div>
                    {/* <!-- End .banner-content --> */}
                  </div>
                  {/* <!-- End .banner --> */}
                </div>
                {/* <!-- End .col-lg-4 --> */}
              </div>
              {/* <!-- End .row --> */}
            </div>
            {/* <!-- End .banner-group --> */}
          </div>
          {/* <!-- End .container --> */}
        </div>
        {/* <!-- End .bg-lighter --> */}

        <div class="mb-3"></div>
        {/* <!-- End .mb-6 --> */}

        <div class="mb-3 mb-xl-2"></div>
        {/* <!-- End .mb-3 --> */}

        <div class="mb-5"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="mb-7"></div>
        {/* <!-- End .mb-5 --> */}

        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6">
              <div class="icon-box icon-box-card text-center">
                <span class="icon-box-icon">
                  <i class="icon-rocket"></i>
                </span>
                <div class="icon-box-content">
                  <h3 class="icon-box-title">Payment & Delivery</h3>
                  {/* <!-- End .icon-box-title --> */}
                  <p>Free shipping for orders over $500</p>
                </div>
                {/* <!-- End .icon-box-content --> */}
              </div>
              {/* <!-- End .icon-box --> */}
            </div>
            {/* <!-- End .col-lg-4 col-sm-6 --> */}

            <div class="col-lg-4 col-sm-6">
              <div class="icon-box icon-box-card text-center">
                <span class="icon-box-icon">
                  <i class="icon-rotate-left"></i>
                </span>
                <div class="icon-box-content">
                  <h3 class="icon-box-title">Return & Refund</h3>
                  {/* <!-- End .icon-box-title --> */}
                  <p>Free 100% money back guarantee</p>
                </div>
                {/* <!-- End .icon-box-content --> */}
              </div>
              {/* <!-- End .icon-box --> */}
            </div>
            {/* <!-- End .col-lg-4 col-sm-6 --> */}

            <div class="col-lg-4 col-sm-6">
              <div class="icon-box icon-box-card text-center">
                <span class="icon-box-icon">
                  <i class="icon-life-ring"></i>
                </span>
                <div class="icon-box-content">
                  <h3 class="icon-box-title">Quality Support</h3>
                  {/* <!-- End .icon-box-title --> */}
                  <p>Alway online feedback 24/7</p>
                </div>
                {/* <!-- End .icon-box-content --> */}
              </div>
              {/* <!-- End .icon-box --> */}
            </div>
            {/* <!-- End .col-lg-4 col-sm-6 --> */}
          </div>
          {/* <!-- End .row --> */}
        </div>
        {/* <!-- End .container --> */}
        <PageContent />
      </main>
    </>
  );
};

export default VarsityJacket;
