import React from "react";

const Aside = () => {
  return (
    <aside class="col-lg-4">
      <div class="summary">
        <h3 class="summary-title">Your Order</h3>

        {/* <!-- End .table table-summary --> */}

        <div class="accordion-summary" id="accordion-payment">
          <div class="card">
            <div class="card-header" id="heading-1">
              <h2 class="card-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-1"
                  aria-expanded="true"
                  aria-controls="collapse-1"
                >
                  Direct bank transfer
                </a>
              </h2>
            </div>
            {/* <!-- End .card-header --> */}
            <div
              id="collapse-1"
              class="collapse show"
              aria-labelledby="heading-1"
              data-parent="#accordion-payment"
            >
              <div class="card-body">
                Make your payment directly into our bank account. Please use
                your Order ID as the payment reference. Your order will not be
                shipped until the funds have cleared in our account.
              </div>
              {/* <!-- End .card-body --> */}
            </div>
            {/* <!-- End .collapse --> */}
          </div>
          {/* <!-- End .card --> */}

          <div class="card">
            <div class="card-header" id="heading-2">
              <h2 class="card-title">
                <a
                  class="collapsed"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-2"
                  aria-expanded="false"
                  aria-controls="collapse-2"
                >
                  Check payments
                </a>
              </h2>
            </div>
            {/* <!-- End .card-header --> */}
            <div
              id="collapse-2"
              class="collapse"
              aria-labelledby="heading-2"
              data-parent="#accordion-payment"
            >
              <div class="card-body">
                Ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.
                Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
              </div>
              {/* <!-- End .card-body --> */}
            </div>
            {/* <!-- End .collapse --> */}
          </div>
          {/* <!-- End .card --> */}

          <div class="card">
            <div class="card-header" id="heading-3">
              <h2 class="card-title">
                <a
                  class="collapsed"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-3"
                  aria-expanded="false"
                  aria-controls="collapse-3"
                >
                  Cash on delivery
                </a>
              </h2>
            </div>
            {/* <!-- End .card-header --> */}
            <div
              id="collapse-3"
              class="collapse"
              aria-labelledby="heading-3"
              data-parent="#accordion-payment"
            >
              <div class="card-body">
                Quisque volutpat mattis eros. Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit. Donec odio. Quisque volutpat
                mattis eros.
              </div>
              {/* <!-- End .card-body --> */}
            </div>
            {/* <!-- End .collapse --> */}
          </div>
          {/* <!-- End .card --> */}

          <div class="card">
            <div class="card-header" id="heading-4">
              <h2 class="card-title">
                <a
                  class="collapsed"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-4"
                  aria-expanded="false"
                  aria-controls="collapse-4"
                >
                  PayPal{" "}
                  <small class="float-right paypal-link">What is PayPal?</small>
                </a>
              </h2>
            </div>
            {/* <!-- End .card-header --> */}
            <div
              id="collapse-4"
              class="collapse"
              aria-labelledby="heading-4"
              data-parent="#accordion-payment"
            >
              <div class="card-body">
                Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra
                non, semper suscipit, posuere a, pede. Donec nec justo eget
                felis facilisis fermentum.
              </div>
              {/* <!-- End .card-body --> */}
            </div>
            {/* <!-- End .collapse --> */}
          </div>
          {/* <!-- End .card --> */}

          <div class="card">
            <div class="card-header" id="heading-5">
              <h2 class="card-title">
                <a
                  class="collapsed"
                  role="button"
                  data-toggle="collapse"
                  href="#collapse-5"
                  aria-expanded="false"
                  aria-controls="collapse-5"
                >
                  Credit Card (Stripe)
                  <img
                    src="assets/images/payments-summary.png"
                    alt="payments cards"
                  />
                </a>
              </h2>
            </div>
            {/* <!-- End .card-header --> */}
            <div
              id="collapse-5"
              class="collapse"
              aria-labelledby="heading-5"
              data-parent="#accordion-payment"
            >
              <div class="card-body">
                {" "}
                Donec nec justo eget felis facilisis fermentum.Lorem ipsum dolor
                sit amet, consectetuer adipiscing elit. Donec odio. Quisque
                volutpat mattis eros. Lorem ipsum dolor sit ame.
              </div>
              {/* <!-- End .card-body --> */}
            </div>
            {/* <!-- End .collapse --> */}
          </div>
          {/* <!-- End .card --> */}
        </div>
        {/* <!-- End .accordion --> */}

        <button
          type="submit"
          class="btn btn-outline-primary-2 btn-order btn-block"
        >
          <span class="btn-text">Place Order</span>
          <span class="btn-hover-text">Proceed to Checkout</span>
        </button>
      </div>
      {/* <!-- End .summary --> */}
    </aside>
  );
};

export default Aside;
