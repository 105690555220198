import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchProductById,
  fetchProductsByCategory,
} from "../../features/product/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import ReactImageMagnify from "react-image-magnify";
import { ImageZoomContainer } from "./style";
import { LoadDisplayContainer } from "../profits-polos/style";
import { BallTriangle } from "react-loader-spinner";
import { addItem } from "../../features/cart/cartSlice";
import { toast } from "react-toastify";
import QRCode from "qrcode";
import Modal from "../modal";
import OwlCarousel from "react-owl-carousel";
const ProductDetail = () => {
  const { productId } = useParams();
  const [requiredQuantity, setRequiredQuantity] = useState(1);
  const [modalDisplay, setModalDisplay] = useState(false);
  const [qrcode, setQrcode] = useState("");
  // const [zoomImage, setZoomImage] = useState("");
  const dispatch = useDispatch();

  const description = {
    LIFTING_SUITS:
      "Engineered for peak performance and unparalleled comfort. Crafted from a blend of high-strength, breathable fabrics, this suit provides optimal support and flexibility for your most demanding workouts. Whether you're powerlifting, bodybuilding, or hitting the gym, our lifting suit material is designed to enhance your range of motion, wick away moisture, and reduce friction.",

    PROFITS_AND_POLOS:
      "Elevate your casual style with our classic Polo. Crafted from the finest, soft material, this timeless piece offers comfort and sophistication in one. The tailored fit and timeless design make it perfect for any occasion, from a relaxed day out to a semi-formal event.",

    VARSITY_JACKETS:
      "Step into classic cool with our Varsity Jacket. Crafted with high-quality materials and attention to detail, this iconic piece exudes style and nostalgia. The timeless design, featuring contrasting sleeves and a buttoned front, adds a touch of vintage charm to your wardrobe. Perfect for those looking to make a statement, our Varsity Jacket is a must-have for any fashion-forward individual. Merging the gap between fashion and Nursing.",

    TRACK_SUITS:
      "Elevate your athleisure game with our Track Suit. This sleek and sporty ensemble combines style and performance. Crafted from high-quality, breathable materials, it's perfect for workouts or casual wear. The modern design and comfortable fit make it a versatile addition to your wardrobe. Step out in confidence and style with our Track Suit, your go-to choice for active and leisure days.",

    NURSING_SCRUB:
      "Introducing our fashionable top-quality Nursing Scrub, tailored for healthcare professionals. These scrubs combine comfort and functionality, featuring soft, breathable fabric and ample pocket space for your essentials. With a modern, professional design, you can work in style while ensuring optimum hygiene. Elevate your work attire with our Nursing Scrub, designed to support your dedication to care and comfort.",

    CROP_TOP_AND_LEGGINGS:
      "Elevate your summer wardrobe with our chic Crop Top and athletic pants. Crafted for style and comfort, it's the perfect piece for those sun-soaked days. The flattering cut and soft fabric provide a relaxed fit, making it ideal for casual outings or dressing up for a night on the town. Stay trendy and cool in our Crop Top and athletic pants, your go-to choice for sizzling style this season.",
  };

  useEffect(() => {
    dispatch(fetchProductById(productId));
    // setZoomImage(product.frontImage);
    dispatch(fetchProductsByCategory(`${product.category}`));
  }, []);

  // console.log(zoomImage, "zoom image");
  const product = useSelector((state) => {
    return state.products.singleProduct;
  });
  const products = useSelector((state) => {
    return state.products;
  });

  const [zoomImage, setZoomImage] = useState(product.frontImage);

  console.log(products, "from signle product");

  const handleShowZoomImage = (image) => {
    setZoomImage(image);
  };

  const handleQuantityChange = (e) => {
    setRequiredQuantity(e.target.value);
  };

  const GenerateQrCode = () => {
    const url = `https://lif-test-server.vercel.app/product-detail/${productId}`;
    QRCode.toDataURL(url, (err, url) => {
      if (err) return console.error(err);
      console.log(url);
      setQrcode(url);
      setModalDisplay(true);
    });
  };

  const handleCloseModal = () => {
    setModalDisplay(false);
  };

  const handleAddToCart = () => {
    console.log(product, "add product to cart");
    // Dispatch the addItem action when the "Add to Cart" button is clicked
    dispatch(addItem({ ...product, requiredQuantity }));
    toast("item added to cart");
  };
  return (
    <main class="main">
      {modalDisplay === true && (
        <Modal qrCode={qrcode} closeModal={handleCloseModal} />
      )}
      <nav aria-label="breadcrumb" class="breadcrumb-nav border-0 mb-0">
        <div class="container d-flex align-items-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="index-25.html">Home</a>
            </li>
            <li class="breadcrumb-item">
              <a href="#">Products</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Centered
            </li>
          </ol>

          <nav class="product-pager ml-auto" aria-label="Product">
            <a
              class="product-pager-link product-pager-prev"
              href="#"
              aria-label="Previous"
              tabindex="-1"
            >
              <i class="icon-angle-left"></i>
              <span>Prev</span>
            </a>

            <a
              class="product-pager-link product-pager-next"
              href="#"
              aria-label="Next"
              tabindex="-1"
            >
              <span>Next</span>
              <i class="icon-angle-right"></i>
            </a>
          </nav>
          {/* <!-- End .pager-nav --> */}
        </div>
        {/* <!-- End .container --> */}
      </nav>
      {/* <!-- End .breadcrumb-nav --> */}

      <div class="page-content">
        <div class="container">
          <div class="product-details-top mb-2">
            <div class="row">
              <div class="col-md-6">
                <div class="product-gallery product-gallery-vertical">
                  {!product && (
                    <LoadDisplayContainer>
                      <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#4fa94d"
                        ariaLabel="ball-triangle-loading"
                        wrapperClass={{}}
                        wrapperStyle=""
                        visible={true}
                      />
                    </LoadDisplayContainer>
                  )}
                  <div class="row">
                    <figure class="product-main-image">
                      {/* <Zoom>
                        <img
                          id="product-zoom"
                          src={product?.frontImage}
                          data-zoom-image={product?.frontImage}
                          alt="product image"
                        />
                      </Zoom> */}
                      <ImageZoomContainer>
                        <ReactImageMagnify
                          enlargedImageContainerClassName="largeImageContainer"
                          className="imageRootContainer"
                          enlargedImageContainerStyle={{
                            backgroundColor: "#FFFFFF",
                            zIndex: 100,
                            position: "absolute",
                            right: 0,
                            left: 0,
                          }}
                          {...{
                            smallImage: {
                              alt: "Wristwatch by Ted Baker London",
                              isFluidWidth: true,
                              src: `${
                                zoomImage ? zoomImage : product.frontImage
                              }`,
                            },
                            largeImage: {
                              src: `${
                                zoomImage ? zoomImage : product.frontImage
                              }`,
                              width: 1200,
                              height: 1800,
                            },
                          }}
                        />
                      </ImageZoomContainer>
                      <a
                        href="#"
                        id="btn-product-gallery"
                        class="btn-product-gallery"
                      >
                        <i class="icon-arrows"></i>
                      </a>
                    </figure>
                    {/* <!-- End .product-main-image --> */}

                    <div
                      id="product-zoom-gallery"
                      class="product-image-gallery"
                    >
                      <a
                        class="product-gallery-item active"
                        href="#"
                        onClick={() => handleShowZoomImage(product?.frontImage)}
                        data-image={product?.frontImage}
                        data-zoom-image={product?.frontImage}
                      >
                        <img src={product?.frontImage} alt="product side" />
                      </a>

                      <a
                        class="product-gallery-item"
                        href="#"
                        onClick={() => handleShowZoomImage(product?.backImage)}
                        data-image={product?.frontImage}
                        data-zoom-image={product?.frontImage}
                      >
                        <img src={product?.backImage} alt="product cross" />
                      </a>

                      <a
                        class="product-gallery-item"
                        href="#"
                        onClick={() => handleShowZoomImage(product?.frontImage)}
                        data-image={product?.frontImage}
                        data-zoom-image={product?.frontImage}
                      >
                        <img
                          src={product?.frontImage}
                          alt="product with model"
                        />
                      </a>

                      <a
                        class="product-gallery-item"
                        href="#"
                        onClick={() => handleShowZoomImage(product?.backImage)}
                        data-image={product?.frontImage}
                        data-zoom-image={product?.frontImage}
                      >
                        <img src={product?.backImage} alt="product back" />
                      </a>
                    </div>
                    {/* <!-- End .product-image-gallery --> */}
                  </div>
                  {/* <!-- End .row --> */}
                </div>
                {/* <!-- End .product-gallery --> */}
              </div>
              {/* <!-- End .col-md-6 --> */}

              <div class="col-md-6">
                <div class="product-details product-details-centered">
                  <h1 class="product-title">{product?.name}</h1>
                  {/* <!-- End .product-title --> */}

                  <div class="ratings-container">
                    <div class="ratings">
                      <div class="ratings-val" style={{ width: "80%" }}></div>
                      {/* <!-- End .ratings-val --> */}
                    </div>
                    {/* <!-- End .ratings --> */}
                    <a
                      class="ratings-text"
                      href="#product-review-link"
                      id="review-link"
                    >
                      ( 2 Reviews )
                    </a>
                  </div>
                  {/* <!-- End .rating-container --> */}

                  <div
                    style={{
                      textAlign: "center !important",
                      display: "flex !important",
                      width: "10% !important",
                      justifyContent: "center !important",
                      alignItems: "center !important",
                      fontSize: "30px !important",
                    }}
                  >
                    <h3>${product?.price}</h3>
                  </div>
                  {/* <!-- End .product-price --> */}

                  <div class="product-content">
                    <p>{product?.description}</p>
                  </div>
                  {/* <!-- End .product-content --> */}

                  <div class="details-filter-row details-row-size">
                    <label>Color:</label>

                    <div class="product-nav product-nav-dots">
                      <a
                        href="#"
                        class="active"
                        style={{ background: "#cc9966" }}
                      >
                        <span class="sr-only">Color name</span>
                      </a>
                      <a href="#" style={{ background: "#333333" }}>
                        <span class="sr-only">Color name</span>
                      </a>
                    </div>
                    {/* <!-- End .product-nav --> */}
                  </div>
                  {/* <!-- End .details-filter-row --> */}

                  <div class="details-filter-row details-row-size">
                    <label for="size">Size:</label>
                    <div class="select-custom">
                      <select name="size" id="size" class="form-control">
                        <option value="#" selected="selected">
                          One Size
                        </option>
                        <option value="xxs">XXS</option>
                        <option value="xs">XS</option>
                        <option value="s">S</option>
                        <option value="m">M</option>
                        <option value="l">L</option>
                        <option value="xl">XL</option>
                        <option value="xxl">XXL</option>
                        <option value="xxxl">XXXL</option>
                      </select>
                    </div>
                    {/* <!-- End .select-custom --> */}

                    {/* <a href="#" class="size-guide">
                      <i class="icon-th-list"></i>size guide
                    </a> */}
                  </div>
                  {/* <!-- End .details-filter-row --> */}

                  <div class="product-details-action">
                    <div class="details-action-col">
                      <div class="product-details-quantity">
                        <input
                          type="number"
                          id="qty"
                          class="form-control"
                          value={requiredQuantity}
                          onChange={handleQuantityChange}
                          min="1"
                          max="10"
                          step="1"
                          data-decimals="0"
                          required
                        />
                      </div>
                      {/* <!-- End .product-details-quantity --> */}

                      <a
                        href="#"
                        onClick={handleAddToCart}
                        class="btn-product btn-cart"
                      >
                        <span>add to cart</span>
                      </a>
                    </div>
                    {/* <!-- End .details-action-col --> */}

                    <div class="details-action-wrapper">
                      <a
                        href="#"
                        class="btn-product btn-compare"
                        title="Compare"
                        onClick={GenerateQrCode}
                      >
                        <span>Generate Qr Code</span>
                      </a>
                    </div>
                    {/* <!-- End .details-action-wrapper --> */}
                  </div>
                  {/* <!-- End .product-details-action --> */}

                  <div class="product-details-footer">
                    <div class="product-cat">
                      <span>Category:</span>
                      <a href="#">{product?.category}</a>,
                    </div>
                    {/* <!-- End .product-cat --> */}

                    <div class="social-icons social-icons-sm">
                      <span class="social-label">Share:</span>
                      <a
                        href="#"
                        class="social-icon"
                        title="Facebook"
                        target="_blank"
                      >
                        <i class="icon-facebook-f"></i>
                      </a>
                      <a
                        href="#"
                        class="social-icon"
                        title="Twitter"
                        target="_blank"
                      >
                        <i class="icon-twitter"></i>
                      </a>
                      <a
                        href="#"
                        class="social-icon"
                        title="Instagram"
                        target="_blank"
                      >
                        <i class="icon-instagram"></i>
                      </a>
                      <a
                        href="#"
                        class="social-icon"
                        title="Pinterest"
                        target="_blank"
                      >
                        <i class="icon-pinterest"></i>
                      </a>
                    </div>
                  </div>
                  {/* <!-- End .product-details-footer --> */}
                </div>
                {/* <!-- End .product-details --> */}
              </div>
              {/* <!-- End .col-md-6 --> */}
            </div>
            {/* <!-- End .row --> */}
          </div>
          {/* <!-- End .product-details-top --> */}

          <div class="product-details-tab">
            <ul class="nav nav-pills justify-content-center" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="product-desc-link"
                  data-toggle="tab"
                  href="#product-desc-tab"
                  role="tab"
                  aria-controls="product-desc-tab"
                  aria-selected="true"
                >
                  Description
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="product-shipping-link"
                  data-toggle="tab"
                  href="#product-shipping-tab"
                  role="tab"
                  aria-controls="product-shipping-tab"
                  aria-selected="false"
                >
                  Shipping & Returns
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="product-review-link"
                  data-toggle="tab"
                  href="#product-review-tab"
                  role="tab"
                  aria-controls="product-review-tab"
                  aria-selected="false"
                >
                  Reviews (2)
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div
                class="tab-pane fade show active"
                id="product-desc-tab"
                role="tabpanel"
                aria-labelledby="product-desc-link"
              >
                <div class="product-desc-content">
                  <h3>Product Information</h3>
                  {product?.category === "nursing scrubs" && (
                    <p>{description.NURSING_SCRUB}</p>
                  )}
                  {product?.category === "lifting suits" && (
                    <p>{description.LIFTING_SUITS}</p>
                  )}
                  {product?.category === "track suits" && (
                    <p>{description.TRACK_SUITS}</p>
                  )}
                  {product?.category === "profits and polos" && (
                    <p>{description.PROFITS_AND_POLOS}</p>
                  )}
                  {product?.category === "280 degrees" && (
                    <p>{description.CROP_TOP_AND_LEGGINGS}</p>
                  )}
                  {product?.category === "varsity jackets" && (
                    <p>{description.VARSITY_JACKETS}</p>
                  )}
                  {product?.category === "crow top and leggings" && (
                    <p>{description.CROP_TOP_AND_LEGGINGS}</p>
                  )}
                  {product?.category === "all-home" && (
                    <p>{description.TRACK_SUITS}</p>
                  )}
                  {product?.category === "home-clothing" && (
                    <p>{description.CROP_TOP_AND_LEGGINGS}</p>
                  )}
                  {product?.category === "home-shoes" && (
                    <p>{description.CROP_TOP_AND_LEGGINGS}</p>
                  )}
                  {product?.category === "profit and polos two" && (
                    <p>{description.PROFITS_AND_POLOS}</p>
                  )}
                  {product?.category === "home-accessories" && (
                    <p>{description.CROP_TOP_AND_LEGGINGS}</p>
                  )}
                  {product?.category === "features" && (
                    <p>{description.CROP_TOP_AND_LEGGINGS}</p>
                  )}
                  {product?.category === "on sale" && (
                    <p>{description.CROP_TOP_AND_LEGGINGS}</p>
                  )}
                  {product?.category === "top-rated" && (
                    <p>{description.CROP_TOP_AND_LEGGINGS}</p>
                  )}
                </div>
                {/* <!-- End .product-desc-content --> */}
              </div>
              {/* <!-- .End .tab-pane --> */}

              {/* <!-- .End .tab-pane --> */}
              <div
                class="tab-pane fade"
                id="product-shipping-tab"
                role="tabpanel"
                aria-labelledby="product-shipping-link"
              >
                <div class="product-desc-content">
                  <h3>Delivery & returns</h3>
                  <p>
                    We deliver to over 100 countries around the world.
                    <br />
                    We hope you’ll love every purchase, but if you ever need to
                    return an item you can do so within a week of receipt.
                  </p>
                </div>
                {/* <!-- End .product-desc-content --> */}
              </div>
              {/* <!-- .End .tab-pane --> */}
              <div
                class="tab-pane fade"
                id="product-review-tab"
                role="tabpanel"
                aria-labelledby="product-review-link"
              >
                <div class="reviews">
                  <h3>Reviews (2)</h3>
                  <div class="review">
                    <div class="row no-gutters">
                      <div class="col-auto">
                        <h4>
                          <a href="#">Samanta J.</a>
                        </h4>
                        <div class="ratings-container">
                          <div class="ratings">
                            <div
                              class="ratings-val"
                              style={{ width: "80%" }}
                            ></div>
                            {/* <!-- End .ratings-val --> */}
                          </div>
                          {/* <!-- End .ratings --> */}
                        </div>
                        {/* <!-- End .rating-container --> */}
                        <span class="review-date">6 days ago</span>
                      </div>
                      {/* <!-- End .col --> */}
                      <div class="col">
                        <h4>Good, perfect design</h4>

                        <div class="review-content">
                          <p>
                            I adore the design of this cloth. The color and
                            pattern are exactly as shown in the online photos.
                            It's a versatile piece that can be dressed up or
                            down, making it suitable for various occasions. The
                            attention to detail in the design is evident, and it
                            reflects a stylish and modern aesthetic.
                          </p>
                        </div>
                        {/* <!-- End .review-content --> */}

                        <div class="review-action">
                          <a href="#">
                            <i class="icon-thumbs-up"></i>Helpful (2)
                          </a>
                          <a href="#">
                            <i class="icon-thumbs-down"></i>Unhelpful (0)
                          </a>
                        </div>
                        {/* <!-- End .review-action --> */}
                      </div>
                      {/* <!-- End .col-auto --> */}
                    </div>
                    {/* <!-- End .row --> */}
                  </div>
                  {/* <!-- End .review --> */}

                  <div class="review">
                    <div class="row no-gutters">
                      <div class="col-auto">
                        <h4>
                          <a href="#">Regina</a>
                        </h4>
                        <div class="ratings-container">
                          <div class="ratings">
                            <div
                              class="ratings-val"
                              style={{ width: "100%" }}
                            ></div>
                            {/* <!-- End .ratings-val --> */}
                          </div>
                          {/* <!-- End .ratings --> */}
                        </div>
                        {/* <!-- End .rating-container --> */}
                        <span class="review-date">5 days ago</span>
                      </div>
                      {/* <!-- End .col --> */}
                      <div class="col">
                        <h4>Fair pricing</h4>

                        <div class="review-content">
                          <p>
                            The price point for this cloth is incredibly
                            reasonable for the quality and design it offers. I
                            consider it a great value for my money, and I would
                            have happily paid more for such a high-quality item.
                          </p>
                        </div>
                        {/* <!-- End .review-content --> */}

                        <div class="review-action">
                          <a href="#">
                            <i class="icon-thumbs-up"></i>Helpful (0)
                          </a>
                          <a href="#">
                            <i class="icon-thumbs-down"></i>Unhelpful (0)
                          </a>
                        </div>
                        {/* <!-- End .review-action --> */}
                      </div>
                      {/* <!-- End .col-auto --> */}
                    </div>
                    {/* <!-- End .row --> */}
                  </div>
                  {/* <!-- End .review --> */}
                </div>
                {/* <!-- End .reviews --> */}
              </div>
              {/* <!-- .End .tab-pane --> */}
            </div>
            {/* <!-- End .tab-content --> */}
          </div>
          {/* <!-- End .product-details-tab --> */}

          <h2 class="title text-center mb-4">You May Also Like</h2>
          {/* <!-- End .title text-center --> */}
          <OwlCarousel
            className="owl-carousel owl-simple carousel-equal-height carousel-with-shadow"
            data-toggle="owl"
            items={5}
            autoplay={true}
            margin={20}
            dots={true}
            loop={true}
            data-owl-options='{
                            "nav": false, 
                            "dots": true,
                            "margin": 20,
                            "loop": false,
                            "responsive": {
                                "0": {
                                    "items":1
                                },
                                "480": {
                                    "items":2
                                },
                                "768": {
                                    "items":3
                                },
                                "992": {
                                    "items":4
                                },
                                "1200": {
                                    "items":4,
                                    "nav": true,
                                    "dots": false
                                }
                            }
                        }'
          >
            {products?.isFetching === true && (
              <LoadDisplayContainer>
                <BallTriangle
                  height={100}
                  width={100}
                  radius={5}
                  color="#4fa94d"
                  ariaLabel="ball-triangle-loading"
                  wrapperClass={{}}
                  wrapperStyle=""
                  visible={true}
                />
              </LoadDisplayContainer>
            )}
            {products?.items?.map((product) => (
              <div class="product product-7 text-center">
                <figure class="product-media">
                  <span class="product-label label-new">New</span>
                  <a href="product.html">
                    <img
                      src="assets/images/products/product-4.jpg"
                      alt="Product image"
                      class="product-image"
                    />
                  </a>

                  <div class="product-action-vertical">
                    <a
                      href="#"
                      class="btn-product-icon btn-wishlist btn-expandable"
                    >
                      <span>add to wishlist</span>
                    </a>
                    <a
                      href="popup/quickView.html"
                      class="btn-product-icon btn-quickview"
                      title="Quick view"
                    >
                      <span>Quick view</span>
                    </a>
                    <a
                      href="#"
                      class="btn-product-icon btn-compare"
                      title="Compare"
                    >
                      <span>Compare</span>
                    </a>
                  </div>
                  {/* <!-- End .product-action-vertical --> */}

                  <div class="product-action">
                    <a href="#" class="btn-product btn-cart">
                      <span>add to cart</span>
                    </a>
                  </div>
                  {/* <!-- End .product-action --> */}
                </figure>
                {/* <!-- End .product-media --> */}

                <div class="product-body">
                  <div class="product-cat">
                    <a href="#">Women</a>
                  </div>
                  {/* <!-- End .product-cat --> */}
                  <h3 class="product-title">
                    <a href="product.html">
                      Brown paperbag waist <br />
                      pencil skirt
                    </a>
                  </h3>
                  {/* <!-- End .product-title --> */}
                  <div class="product-price">$60.00</div>
                  {/* <!-- End .product-price --> */}
                  <div class="ratings-container">
                    <div class="ratings">
                      <div class="ratings-val" style={{ width: "20%" }}></div>
                      {/* <!-- End .ratings-val --> */}
                    </div>
                    {/* <!-- End .ratings --> */}
                    <span class="ratings-text">( 2 Reviews )</span>
                  </div>
                  {/* <!-- End .rating-container --> */}

                  <div class="product-nav product-nav-dots">
                    <a
                      href="#"
                      class="active"
                      style={{ background: "#cc9966" }}
                    >
                      <span class="sr-only">Color name</span>
                    </a>
                    <a href="#" style={{ background: "#333333" }}>
                      <span class="sr-only">Color name</span>
                    </a>
                    <a href="#" style={{ background: "#806b3e" }}>
                      <span class="sr-only">Color name</span>
                    </a>
                  </div>
                  {/* <!-- End .product-nav --> */}
                </div>
                {/* <!-- End .product-body --> */}
              </div>
            ))}

            {/* <!-- End .product --> */}
          </OwlCarousel>
          {/* <!-- End .owl-carousel --> */}
        </div>
        {/* <!-- End .container --> */}
      </div>
      {/* <!-- End .page-content --> */}
    </main>
  );
};

export default ProductDetail;
