import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducts,
  fetchProductsByCategory,
} from "../../../../features/product/productsSlice";
const FeaturedTabs = ({ handleFeatures, handleOnsale, handleTopRated }) => {
  return (
    <div class="container">
      <ul
        class="nav nav-pills nav-border-anim nav-big justify-content-center mb-3"
        role="tablist"
      >
        <li class="nav-item">
          <a
            class="nav-link active"
            id="products-featured-link"
            data-toggle="tab"
            href="#products-featured-tab"
            role="tab"
            onClick={handleFeatures}
            aria-controls="products-featured-tab"
            aria-selected="true"
          >
            Featured
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="products-sale-link"
            data-toggle="tab"
            href="#products-sale-tab"
            role="tab"
            onClick={handleOnsale}
            aria-controls="products-sale-tab"
            aria-selected="false"
          >
            On Sale
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="products-top-link"
            data-toggle="tab"
            href="#products-top-tab"
            role="tab"
            onClick={handleTopRated}
            aria-controls="products-top-tab"
            aria-selected="false"
          >
            Top Rated
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FeaturedTabs;
